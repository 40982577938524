.admin-panel {
    .form-group {
        margin-bottom: 1.25rem;
    }
    .form-control {
        border: 1px solid $__light__goose;
        box-shadow: $__none;
        color: $__gray;
        font-size: $__font__size__15;
        height: 40px;
        &:focus{
            border-color: $__light__goose;
            box-shadow: $__none;
        }
    }
    .manidory{
        color:$__red;
    }
    .form-links a {
        color: $__deep__skyblue;
        text-decoration: $__underline;
        font-size: $__font__size__14;
    }
    textarea.form-control {
        height: 150px;
    }
    input[type=file].form-control {
        height: 36px;
    }
}
