.admin-panel {
    .content {
        min-height: 200px;
        @include margin-padding(null, 30px 0 0);
        @include respond-below(custom767) {
            @include margin-padding(null, 15px 0 0);
        }
        #filter_inputs {
            display: $__none;
        }
    }
    .card {
        border: 1px solid $__light-silver;
        margin-bottom: 1.875rem;
        @include respond-below(custom767) {
            margin-bottom: 20px;
        }
        @include respond-below(custom575) {
            margin-bottom: 0.9375rem;
        }
        .card-header {
            background-color: $__white;
            border-bottom: 1px solid $__light-silver;
            @include respond-below(custom575) {
                @include margin-padding(null, .75rem 1.25rem);
            }
            .card-title {
                margin-bottom: 0;
            }
        }
    }
    .card-body {
        @include margin-padding(null, 1.5rem);
        @include respond-below(custom575) {
            @include margin-padding(null, 1.25rem);
        }
    }
    .card-title {
        margin-bottom: 15px;
    }
    .card-header {
        border-bottom: 1px solid $__light-silver;
        @include margin-padding(null, 1rem 1.5rem);
        @include respond-below(custom575) {
            @include margin-padding(null, .75rem 1.25rem);
        }
    }
    .card-footer {
        background-color: $__white;
        border-top: 1px solid $__light-silver;
        @include margin-padding(null, 1rem 1.5rem);
        @include respond-below(custom575) {
            @include margin-padding(null, .75rem 1.25rem);
        }
    }
    .custom-modal {
        .modal-content {
            border: 0;
            @include rounded(10px);
            .modal-header {
                @include margin-padding(null, 1.25rem);
                .modal-title {
                    font-size: $__font__size__20;
                }
            }
        }
        .close {
            background-color: $__ash__gray;
            color: $__white;
            font-size:  $__font__size__17;
            height: 20px;
            line-height: 20px;
            opacity: 1;
            width: 20px;
            z-index: 99;
            @include rounded(50%);
            @include margin-padding(0, 0);
            @include position($__absolute, 26px, 20px, null, null);
        }
        .modal-body {
            @include margin-padding(null, 1.25rem);
        }
        .modal-footer {
            @include margin-padding(null, 1.25rem);
            .btn-outline {
                margin: .25rem $__auto;
            }
        }
    }
}
