.call-us {
    background: url(../../../assets/img/hero/hero-4.jpg);
    background-position: center 60%;
    background-size: cover;
    @include margin-padding(0,90px 0);
    span {
        color: $__primarycolor;
    }
    h2 {
        color: $__white;
        font-size:$__font__size__36;
        @include margin-padding(0,10px 0 0);
        font-weight: $__semibold;
    }
    p {
        color: $__white;
    }
    .call-us-btn {
        @include margin-padding(auto,0);
        text-align: $__right;
        padding-right: 2rem;
        .btn-call-us {
            background: $__primarycolor;
            color: $__white;
            @include margin-padding(0,10px 25px);
            @include transition(all .5s);
        }
    }


}
.about-blk-image {
    position: relative;
    overflow: hidden;
    margin-bottom: 30px;
    border-radius: 15px;
}
