.admin-panel {
    .table {
        color: $__gray;
        max-width: 100%;
        margin-bottom: 0;
        width: $__full__width;

        thead {
            vertical-align: $__bottom;
        }
        tr{
            th {
                font-weight: $__semibold;
                border: 0;
            }
            th {
                padding: 1rem 0.75rem;
                white-space: $__nowrap;
                vertical-align: $__middle;
                &:first-child {
                    padding-left: 1.5rem;
                }
            }
            td {
                border-top: 1px solid $__sea__gray;
                padding: 1rem 0.75rem;
                white-space: $__nowrap;
                vertical-align: $__middle;
                &:first-child {
                    padding-left: 1.5rem;
                }
                a {
                    color: $__gray;
                }
                h2.table-avatar {
                    font-size: $__inherit;
                    font-weight: $__regular;
                    margin: 0;
                    padding: 0;
                    vertical-align: $__middle;
                    white-space: $__nowrap;
                    @extend %align-items-center;
                    @extend %inline-flex;
                }
                @include respond-below(custom992) {
                    overflow: $__hidden;
                    text-overflow: $__ellipsis;
                }
            }
        }
        tbody{
            &:not(:first-child) {
                border-top: 1px solid rgb(0 0 0 / 12%);
            }
        }
        .service-img {
            width: 60px;
        }
        .badge-dark {
            color: $__whitecolor;
            background-color: $__gray;
        }
    }
    .table>:not(caption)>*>* {
        padding: 0.75rem;
        box-shadow: $__unset;
        background-color: $__unset;
    }
    .table-responsive {
        white-space: $__nowrap;
    }
}
