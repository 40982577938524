/****** STYLES ******/
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/bootstrap-grid";
@import "~bootstrap/scss/bootstrap-reboot";

@import "~aos/src/sass/aos";

@import "admin/admin";
@import "front/front";
@import "common/common";
