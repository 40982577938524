.admin-panel {
    .pricing-box {
        .pricing-header {
            @include margin-padding(0 0 2rem ,null);
            h2 {
                @include margin-padding(0,null);
                font-size: $__font__size__20;
                font-weight: $__medium;
                line-height: 1.2;
            }
            p {
                color: $__dark__candy;
                font-size:$__font__size__14;
                line-height: 1.43;
            }
        }
        .pricing-card-price {
            @include margin-padding(0 0 1.563rem ,null);
            .heading2 {
                @include margin-padding(0 0 5px ,null);
            }
        }
        .pricing-options {
            list-style: $__none;
            @include margin-padding(0 0 1.875rem ,0);
            li {
                font-size: $__font__size__14;
                line-height: 1.43;
                position: $__relative;
                @include margin-padding(0 0 12px ,0);
            }
        }
    }
}
