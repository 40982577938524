.admin-panel .btn {
  border: 0;
  cursor: pointer;
  border-radius: 15px;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  padding: 4px 20px;

  &-primary {
    background: $__primarycolor;
    color: #fff;
  }
}
