.filter-list {
    position: $__relative;
    @include margin-padding(0 0 20px,0 0 20px);
    border-bottom: 1px solid $__light-silver;
    .filter-title {
        @include margin-padding(0 0 15px,null);
        font-size: $__font__size__16;
    }
}
.view-icons {
    @extend %inline-flex;
    float:$__right;
}
.service-header{
    @include margin-padding(0 0 30px,null);
    h1 {
        font-weight: $__bold;
    }
}
.fav-btn.fav-btn-big {
    width: $__auto;
    text-align: $__unset;
    justify-content: space-around;
    @include position($__relative,0,0,null,null);
    @include margin-padding(null,0);
    @extend %display-flex; 
    @extend %align-items-center;
    .with-border {
        border: 1px solid $__light__battle__gary;
        width: 36px;
        height: 36px;
        opacity: 1;
        position: $__relative;
        visibility: $__visible;
        color: $__light__battle__gary;
        font-size: $__font__size__16;
        @extend %display-flex; 
        @extend %align-items-center;
        @extend %justify-content-center;
        @include rounded(5px);
        @include transform(translate3d(0, 0, 0));
    }
}
address {
    @include margin-padding(0 0 16px,null);
    font-style: $__font__size__14;
    line-height: $__inherit;
}
.rating {
    list-style: $__none;
    @include margin-padding(0 0 7px,0);
    width:$__full__width;
    .filled {
        color: $__orangecolor;
    }
}
.service-cate {
    a {
        color: $__white;
        text-transform: $__uppercase;
        background: $__orangecolor;
        font-size: $__font__size__14;
        display: $__inline__block;
        @include margin-padding(null,2px 10px);
        @include rounded(4px);
    }
}
