.navbar {
    &.navbar-light {
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 1000;
        //max-height: 35px;
        padding: 0;
    }

    a {
        color: $__white;
    }
}

.navbar-expand-md .navbar-nav {
    position: absolute;
    top: 28px;
    right: 0;
}



.header {
    background: $__basecolor;
    //@include position($__fixed,35px,null,null,0px);
    width: $__full__width;
    z-index: 999;
    .logo img {
        height: $__auto;
        max-width: $__full__width;
        max-height: 55px;
        @include respond-below(custom1199) {
            max-width: 170px !important;
        }
    }


    .dropdown-menu-end {
        a {
            color: $__gray;
        }
    }

    .logo a {
        float: $__left;
        img {
            float: $__left;
            max-height: 32px;
        }
    }
    .navbar-brand {
        @include margin-padding(0 1rem 0 0,0.3125rem 0 );
        font-size: $__font__size__20;
        text-decoration: $__none;
        white-space: $__nowrap;
            &.logo-small{
                display: $__none;
            }
            &.logo img {
                height: $__auto;
                max-width: $__full__width;
                max-height: 55px;
                @include respond-below(custom575) {
                    display: $__none;
                }
            }
        @include respond-below(custom575) {
            &.logo-small {
                display: $__block;
                width:$__full__width;
                text-align: $__center;
                @include margin-padding(0 ,0 100px );
                img {
                    max-height: 40px;
                    width: $__auto;
                }
            }
        }
    }
    .header-nav {
        @extend %align-items-center;
        @extend %display-flex;
        @extend %flex-wrap;
        @extend %justify-content-between;
        @include margin-padding(0 ,0 30px );
        border: 0;
        position: $__relative;
        height: 85px;
        padding-right: 7rem;
            @include respond-below(custom991) {
                @include margin-padding(0 ,0 20px );
            }
            @include respond-below(custom575) {
                @include margin-padding(0 ,0 15px );
            }
            @include respond-below(custom991) {
                @extend %display-flex;
                #mobile_btn {
                    margin-right:30px;
                    .bar-icon {
                        display: $__inline__block;
                        width: 31px;

                        span {
                            background-color: $__whitecolor;
                            display: $__block;
                            float: $__left;
                            height: 3px;
                            margin-bottom: 7px;
                            width: 31px;
                            @include rounded(2px);
                            &:nth-child(2) {
                                width: 16px;
                            }
                        }

                    }


                }

			}
        .header-navbar-rht {
            @include margin-padding(0,0);
            a.header-login {
                background-color: $__primarycolor;
                @include rounded(8px);
                text-align: $__center;
                font-size: $__font__size__14;
                color: $__whitecolor;
                text-transform: $__uppercase;
                font-weight: $__medium;
                @include margin-padding(0 ,8px 12px);
            }
            > li{
                @extend %align-items-center;
                @extend %inline-flex;
                @include margin-padding(0,0 15px 0 0);
                @extend %justify-content-center;
                @include respond-below(custom992) {
                    @include margin-padding(0,0 0 0 5px);
                }
                &:last-child{
                    @include margin-padding(0,0);
                }
            }
            li{
                .dropdown-menu{
                    min-width: 200px;
                    @include margin-padding(0,0);
                }
            }
            .logged-item{
                .nav-link{
                    @extend %align-items-center;
                    @extend %inline-flex;
                    @include margin-padding(0,0 10px);
                    font-size: $__font__size__14;
                    height: 85px;
                    color: $__white;
                    position: $__relative;
                    @include respond-below(custom767) {
                        padding: 0 5px;
                    }
                    .badge {
                        @include position($__absolute,15px,10px,null,null);
                        background: $__orangecolor;
                        @include rounded(50px);
                        @include respond-below(custom767) {
                           display: $__none;
                        }
                    }
                    &::after{
                        border-top: 0;
                        @include respond-below(custom992) {
                            display: $__none;
                        }
                    }
                    i{
                        font-size: $__font__size__24;
                        @include respond-below(custom767) {
                            font-size: $__font__size__16;
                        }
                    }
                }
            }
            @include respond-below(custom992) {
                li.desc-list{
                    display: $__none;
                }
            }
        }
    }
    .main-menu-wrapper {
        @include margin-padding(0 15px 0 auto,null);
        .menu-header {
            height: 60px;
            display: $__none;
            @include margin-padding(null ,0 20px );
            @extend %justify-content-spacebetween;
            @extend %align-items-center;
            .menu-logo img {
                height: $__auto;
                max-width: 180px;
            }
            .menu-close {
                font-size: $__font__size__18;
                color: $__primarycolor;
            }
            @include respond-below(custom991) {
                display: flex;
                align-items: center;
                height: 60px;
                @include margin-padding(null,0 20px);
            }
        }
        .main-nav {
            @extend %ul_reset;
            @extend %display-flex;
            > li{
                margin-right: 20px;
                &:last-child{
                    margin-right: 0;
                }
                > a{
                  color:$__whitecolor;
                  i{
                    font-size: $__font__size__12;
                    margin-left: 3px;
                  }
                }
                &.active{
                    a{
                        &::after{
                            width: $__full__width;
                            right: $__auto;
                            left: 0;
                        }
                    }
                }
            }
            .has-submenu:hover{
                .submenu{
                    visibility: $__visible;
                    opacity: 1;
                    margin-top: 0;
                    @include transform(translateY(0));
                   li {
                        position: $__relative;
                        &:first-child a {
                            border-top: 0;
                        }
                        a{
                            display: $__block;
                            clear: $__both;
                            white-space: $__nowrap;
                            font-size: $__font__size__15;
                            color: $__slate-blue;
                            width: $__full__width;
                            border-top: 1px solid $__light-silver;
                            @include margin-padding(0 ,10px 15px);
                            @include transition(all 0.35s ease);
                        }
                    }
                    &:first-child a {
                        border-top: 0;
                    }
                }
            }
           > li {
                display: $__block;
                position: $__relative;

              >  a{
                    line-height: 85px;
                    padding: 0 !important;
                    display: $__block;
                    font-size: $__font__size__15;
                    font-weight: $__medium;
                    &::after {
                        width: 0;
                        background: $__primarycolor;
                        content: '';
                        height: 4px;
                        @include rounded(4px);
                        @include transition(all 0.3s ease);
                        @include position($__absolute,null,0,0,null);
                    }
                    &:hover::after{
                        width: $__full__width;
                        right: $__auto;
                        left: 0;
                    }
                    @include respond-below(custom991) {
                        line-height: 1.5;
                        padding: 15px 20px !important;
                        color: $__white;
                        font-size: $__font__size__14;
                        font-weight: $__medium;
                        &::after{
                            background: $__transparent;
                        }
                    }
                }
            }
            li.active > a {
                color: $__primarycolor !important;
            }
            li.active > a{
                &:hover{
                    width: $__full__width;
                    right: $__auto;
                    left: 0;
                }
            }
            @include respond-below(custom991){
                @include margin-padding(0 ,0);
                flex-direction: column;
                list-style: $__none;
            }
        }
        @include respond-below(custom991){
            order: 3;
            width: 260px;
            background-color: $__black;
            overflow-x: $__hidden;
            overflow-y: $__auto;
            z-index: 11111;
            @include position($__fixed,0,null,0,0);
            @include transform(translateX(-260px));
            @include transition(all 0.35s);
        }
    }
    .user-img {
        img {
            height: 31px;
            object-fit: $__cover;
            width: 31px;
        }
    }
    .has-arrow{
        .dropdown-toggle{
            &:after{
                border-top: 0;
                border-left: 0;
                border-bottom: 2px solid $__white;
                border-right: 2px solid $__white;
                content: '';
                height: 8px;
                display: $__inline__block;
                pointer-events: $__none;
                width: 8px;
                vertical-align: 2px;
                margin-right: 10px;
                @include transform-origin(66% 66%);
                @include transform(rotate(45deg));
                @include transition(all 0.15s);
            }
        }
        .dropdown-toggle[aria-expanded="true"]:after{
            @include transform(rotate(-135deg));
        }
    }
    .user-header {
        background-color: $__platinum;
        @extend  %display-flex;
        @include margin-padding(null ,10px 15px);
        .user-text {
            margin-left: 10px;
            h6 {
                font-size: $__font__size__15;
                margin-bottom: 2px;
            }
        }

    }
    .dropdown-menu-end{
        .dropdown-item {
            border-top: 1px solid $__light-silver;
            @include margin-padding(null ,10px 15px);
            font-size: $__font__size__14;
        }
    }
    @include respond-below(custom991) {
        .navbar-header{
            display: inline-flex;
            align-items: center;
        }
    }
    @include respond-below(custom575) {
        .navbar-header {
            width: $__full__width;
        }
    }
}
@include respond-below(custom991) {
    .menu-opened .main-menu-wrapper {
        @include transform(translateX(0));
    }
    .main-nav{
        ul {
            display: $__none;
            list-style: $__none;
            @include margin-padding(0, 0);
            .submenu{
                li{
                    a{
                        @include margin-padding(null, 10px 15px 10px 35px);
                    }
                }
            }
        }
    }
    .submenu{
        li {
            a{
                color: $__white;
                border: 0 !important;
            }
        }
    }
    .has-submenu{
        i {
            float: $__right;
            margin-top: 5px;
        }
    }
}
@include respond-above(custom992) {
    .main-nav{
        li{
            display: $__block;
            position: $__relative;
            ul {
                background-color: $__white;
                display: $__block;
                font-size: $__font__size__14;
                min-width: 200px;
                opacity: 0;
                visibility: $__hidden;
                z-index: 1000;
                overflow: $__auto;
                max-height: 300px;
                @include box-shadow(null, 0, 0px, 3px, null, rgba(0, 0, 0, .10));
                @include rounded(5px);
                @include margin-padding(0 ,0);
                @include position($__absolute,100%,null,null,0);
                @include transition(all 0.35s);
                @include transform(translateY(20px));
            }
            .submenu{
                li{
                    a{
                        display: $__block;
                        clear: $__both;
                        white-space: $__nowrap;
                        font-size: $__font__size__14;
                        color: $__slate-blue;
                        width: $__full__width;
                        border-top: 1px solid $__light-silver;
                        @include margin-padding(null ,10px 15px);
                        @include transition(all 0.35s);
                        &:hover {
                            color: $__primarycolor !important;
                            letter-spacing: 0.5px;
                            padding-left: 20px !important;
                        }
                    }
                    &:first-child{
                        a{
                            border-top: 0;
                        }
                    }
                }
            }

        }
    }

}
@include respond-below(custom575) {
    .header-navbar-rht {
        @include position($__absolute,null,10px,null,null);
    }
}
@include respond-below(custom575) {
    #mobile_btn {
        @include margin-padding(0 ,0px 15px);
        @include position($__absolute,null,null,null,0);
        z-index: 99;
    }
}
