.communicator {
    position: fixed;
    bottom: 1%;
    left: 1%;
    z-index: 100000;
    width: 45%;

    @include respond-below(custom992) {
        width: 99%;
    }

    .close {
        position: absolute;
        right: 4%;
        top: 3%;
    }
}
