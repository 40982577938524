.faq-card {
    .card {
        margin-bottom: 20px;
        overflow: $__hidden;
        box-shadow: $__unset;
        background-color: $__light__white;
        @include rounded(0);
        .card-body {
            @include margin-padding(0,1rem);
            h4 {
                @include margin-padding(0,null);
                a {
                    color: $__dark-blue;
                    display: $__block;
                    font-size: $__font__size__18;
                    font-weight: $__regular;
                    padding-right: 60px;
                    position: $__relative;
                    &:after {
                        content: "";
                        width: 10px;
                        height: 10px;
                        border-left: 2px solid $__gray__cloud;
                        border-bottom: 2px solid $__gray__cloud;
                        @include transform(rotate(-45deg));
                        @include position($__absolute,5px,0,null,null);
                    }
                }
            }
        }
    }
    .card-collapse {
        @include margin-padding(null,15px 0 0);
    }
}