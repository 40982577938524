.search-results {
  &__hotel {
    display: flex;

    &-image {
      display: flex;
      height: 250px;
      width: 250px;

      & > div {
        display: flex;
        height: 250px;
        width: 250px;
        background-size: cover !important;
        background-position: center !important;
      }
    }

    &-days {
      font-size: 0.8rem;
    }

    &-price {
      font-size: 0.9rem;
      color: $__basecolor;

      strong {
        font-size: 1.4rem;
        font-weight: 700;
        color: $__primarycolor;
      }
    }

    &-details {
      width: 100%;
      display: flex;
      flex-direction: column;



      &-top {

      }

    &-middle {
        display: flex;
        justify-content: start;
        flex-direction: column;
        align-items: start;

        &__room {
            width: 100%;
            margin-bottom: 3px;
            border: 1px solid #dfdfdf;
            padding: 4px 15px;
            background: #f3f3f3;
            border-radius: 4px;
        }

        .btn-primary {
            background-color: $__basecolor;
            border: 1px solid $__basecolor;
            font-size: 0.9rem;
            padding: 0.5rem 1rem;

            &:hover {
                background-color: $__primarycolor;
                border: 1px solid $__primarycolor;
            }
        }
    }

      &-bottom {
        display: flex;
        justify-content: end;
        flex-direction: column;
        align-items: end;

        .btn-primary {
          background-color: $__basecolor;
          border: 1px solid $__basecolor;
          font-size: 0.9rem;
          padding: 0.5rem 1rem;

          &:hover {
            background-color: $__primarycolor;
            border: 1px solid $__primarycolor;
          }
        }
      }
    }
  }

  label {
      white-space: nowrap;
      font-size: 12px;
      margin-bottom: 0;
  }
}

.pagination {
  text-align: center;
  display: flex;
  width: 100%;
  justify-content: center;
}

.pagination span {
  margin-top: 10px;
}

.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
}

.pagination a.active {
  background-color: $__primarycolor;
  color: white;
  border-radius: 5px;
}

.pagination a:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}

.card {
  border: 1px solid #ecf1f7;
  margin-bottom: 1.875rem;
  box-shadow: 13px 23px 31px -28px rgba(19,41,69, 0.25);
}
