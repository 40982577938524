.admin-panel {
    .user-menu {
        .dropdown-menu {
            min-width: 200px;
            padding: 0;
            .dropdown-item {
                border-top: 1px solid $__metallic-silver;
                padding: 10px 15px !important;
                @extend %display-flex;
                @extend %align-items-center;
            }
        }
        .topnav-dropdown-header{
            font-size:$__font__size__14;
            height: 40px;
            line-height: 40px;
            border-bottom: 1px solid $__cloud__gray;
            text-align: $__center;
            @include margin-padding(null, 0 15px);
            .notification-title {
                color: $__gray;
                display: $__block;
                float: $__left;
                font-size: $__font__size__14;
            }
            .clear-noti {
                color: $__fire__red !important;
                float: $__right;
                font-size: $__font__size__12 !important;
                text-transform: $__uppercase;
            }
        }
        .topnav-dropdown-footer{
            font-size: $__font__size__14;
            height: 50px;
            line-height: 30px;
            border-top: 1px solid $__cloud__gray;
            text-align: $__center;
            @include margin-padding(null,0 15px);
            a {
                display: $__block;
                text-align: $__center;
                color: $__gray;
            }
        }
    }
    .notifications {
        .noti-content {
            height: 290px;
            width: 350px;
            overflow-y: $__auto;
            position: $__relative;
            .notification-list {
                list-style: $__none;
                @include margin-padding(0,0);
                li {
                    margin-top: 0;
                    border-bottom: 1px solid $__dark__light;
                    a {
                        display: $__block;
                        @include rounded(2px);
                        @include margin-padding(null,10px 15px);
                    }
                }
            }
        }
        .media {
            .avatar {
                margin-right: 10px;
            }
            .noti-details {
                color: $__frog__blue;
                margin-bottom: 0;
                .noti-title {
                    color: $__gray;
                }
                .noti-time {
                    margin: 0;
                }
            }
        }
    }
    .admin-noti-wrapper{
        .noti-list {
            @include margin-padding(null,7px 10px);
            @include transition(all 0.2s ease);
            background-color: $__white;
            border: 1px solid $__light__cloud;
            margin-bottom: 5px;
            position: $__relative;
            .noti-avatar {
                left: $__auto;
                height: 40px;
                width: 40px;
                position: $__absolute;
                img {
                    height: 40px;
                    width: 40px;
                }
            }
            .noti-contents {
                margin-left: 50px;
                color: $__light__battle__gary;
                @extend %flex-column;
                @extend %justify-content-center;
                @extend %display-flex;
                h3 {
                    color: $__gray;
                    font-size: $__font__size__16;
                    margin-bottom: 0;
                }
            }
        }
    }
}
