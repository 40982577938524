#loader {
	background-color: $__white;
	z-index: 999999;
	@include position($__fixed, 0, 0, 0, 0);
}
.loader {
	display: $__inline__block;
	height: 50px;
	margin-left: -25px;
	margin-top: -25px;
	width: 50px;
	@include position($__absolute, 50%, null, null, 50%);
	span {
		animation: loader 1.3s linear infinite;
		background-color: $__sky__blue;
		display: $__inline__block;
		height: 50px;
		position: $__absolute;
		width: 50px;
		@include rounded(100%);
		&:last-child {
			animation-delay: -0.8s;
			-webkit-animation-delay: -0.8s;
		}
	}
}
@keyframes loader {
	0% {
		transform: scale(0, 0);
		opacity: 0.5;
	}
	100% {
		transform: scale(1, 1);
		opacity: 0;
	}
}
@-webkit-keyframes loader {
	0% {
		-webkit-transform: scale(0, 0);
		opacity: 0.5;
	}
	100% {
		-webkit-transform: scale(1, 1);
		opacity: 0;
	}
}
.msg-typing {
    width: $__auto;
    height: 24px;
    padding-top: 8px;
	span {
		height: 8px;
		width: 8px;
		float: $__left;
		background-color: $__white;
		display: $__block;
		opacity: .4;
		@include margin-padding(0 1px, null);
		@include rounded(50%);
		&:nth-of-type(1) {
			animation: 1s blink infinite .33333s;
		}
		&:nth-of-type(2) {
			animation: 1s blink infinite .66666s;
		}
		:nth-of-type(3) {
			animation: 1s blink infinite .99999s;
		}
	}
}
@keyframes blink {
	50% {
		opacity: 1
	}
}