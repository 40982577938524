.transaction-table {
    .card-body{
        padding: 0;
        .table>:not(:first-child) {
			border-top: 0;
		}
        th{
            padding: 1rem 0.75rem;
            white-space: $__nowrap;
        }
        td{
            border-top: 1px solid $__sea__gray;
            padding: 1rem 0.75rem;
            white-space: $__nowrap;
            a {
                color: $__gray;
            }
            .pro-avatar {
                height: 1.65rem;
                margin-right: 5px;
                width: $__auto;
                @include rounded(0.25rem);
            }
        }
    }
}
.w-180 {
    width: 180px;
}
.w-155 {
    width: 155px;
}


.front-table {
    thead {
        background-color: $__primarycolor;
    }
    th {
        color: white;
    }
}
