$__primarycolor:#f58220;
$__primarycolor-hover: #db7015;
$__secondarycolor:#F5F5F5;
$__whitecolor:#fff;
$__orangecolor:#fbc418;
$__deep__skyblue: #42c0fb;
$__blue: #15558d;
$__white: #ffffff;
$__basecolor:#132945;
$__dark-blue: #2E3842;
$__slate-blue: #2d3b48;
$__dark-slate-blue: #474648;
$__gray: #333333;
$__dark__gray: #888888;
$__mix__gray: #f7f7f7;
$__dark-gray: #171717;
$__light-gray: #dcdcdc;
$__goose-gray: #cccccc;
$__light__goose: #dddddd;
$__light-goose-gray: #dedfe0;
$__light-silver: #f0f0f0;
$__black: #000000;
$__smokey-gray: #757575;
$__mix__blue__gray: #455a64;
$__light__smokey-gray: #666;
$__dark__smokeygray: #727272;
$__gray__11: #F4F4F4;
$__celeste: #0de0fe;
$__dark-celeste: #01cae4;
$__light__celeste: #20e3ff;
$__light-pale-blue: #f8f9fa;
$__red: #fb1612;
$__green: #28a745;
$__dull-gold: #f4c150;
$__dark__blue: #272b41;
$__sky__blue: #00a5f5;
$__gray__cloud: #999;
$__dark__gray__cloud: #9f9f9f;
$__steel__blue: #1663a6;
$__granite: #858585;
$__light__granite: #8a8a8a;
$__granite__gary: #797979;
$__platinum: #f9f9f9;
$__jet__gray: #C0C0C0;
$__metallic-silver: #e3e3e3;
$__light__metallic: #dfdfdf;
$__dark-inkblue: #3474ac;
$__info__light: #1db9aa;
$__primary__light: #2196f3;
$__danger__light: #e63c3c;
$__warning__light: #f39c12;
$__success__light: #26af48;
$__purple__light: #c580ff;
$__default__light: #283447;
$__default__color:#555a64;
$__light__metalic: #e9e9e9;
$__blue__angel: #cfcfcf;
$__Light__blue__angel: #dcddea;
$__grapefruit: #d9534f;
$__light__grapefruit: #d43f3a;
$__mild__grapefruit: #e48784;
$__violet__pink: #ff4877;
$__ash__gray: #a0a0a0;
$__dark__ash__gray: #555;
$__light__white: #fafafa;
$__dark__light: #f5f5f5;
$__cloud__gray: #eeeeee;
$__gray__wolf: #3e3e3e;
$__wolf: #6c757d;
$__light__wolf: #a3a3a3;
$__water: #f5f7fc;
$__lava__red: #dc3545;
$__metalic__gray: #bbb;
$__bright__gold: #f2b600;
$__water__white: #fcfcfc;
$__light__water__white: #f3f3f3;
$__light__blue__gray: #f5f5f6;
$__baby__blue: #e0e3e4;
$__light__columbia__blue: #d2dde9;
$__lights__blue: #e8ecec;
$__light__battle__gary: #aaa;
$__light__battle__garys: #B0B0B0;
$__dark__battle__gary: #777;
$__royal__blue: #2c80ff;
$__zombie__green: #00d285;
$__dark__marble__blue: #495463;
$__mix__white: #fbfbfb;
$__night: #3d3d3d;
$__dark__cloud: #d4d4d4;
$__light__cloud: #e5e5e5;
$__lapis__blue: #3a559f;
$__dark__lava__red: #dd4b39;
$__dark__beige: #b8b8b8;
$__iridium: #676767;
$__light__chilli__pepper: #fc6075;
$__successcolor: #7bb13c;
$__successcolor-hover: #699834;
$__infocolor: #009efb;
$__infocolor-hover: #028ee1;
$__warningcolor: #ffbc34;
$__warningcolor-hover: #e9ab2e;
$__dangercolor: #e84646;
$__dangercolor-hover: #e63333;
$__purplecolor: #9368e9;
$__purpletext: #7460ee;
$__cherry__red: #f06060;
$__lime__green: #55ce63;
$__aquamarine: #40dda4;
$__spring__green: #2ab934;
$__dark__ash: #212529;
$__dark__candy: #ababab;
$__light__candy: #e6e6e6;
$__candycolor: #a6a6a6;
$__ligt__carbon: #979797;
$__cantaloupe: #ff9b44;
$__dark__plum__purple: #4E4852;
$__turquoise: #0db9f2;
$__beige: #dbdbdb;
$__light__beige: #e4e4e4;
$__light__vampire: #f1f1f1;
$__cyan__blue: #0dd9f9;
$__cyan: #5ae8ff;
$__slick__arrow: #0B0B0B;
$__sea__gray: #e2e5e8;
$__mild___gray: #eaeaea;
$__light__smokey-gray__gray: #737373;
$__light__blue__jey: #1b5a90;
$__gray__powder: #ebebeb;
$__gray__opaque: #e4e8eb;
$__dodger__blue: #177dff;
$__text-secondary: #b8bdc1;
$__ferriari__red: #e0001a;
$__sandstone: #bdbdbd;
$__frog__blue: #989c9e;
$__taupe__black: #4c4c4c;
$__windows__blue: #4b75bd;
$__tomred: #fe5240;
$__saffron: #ffc107;
$__yellow: #ffd200;
$__light__sage: #f2f2f2;
$__cream: #ffffcc;
$__alicegray: #f4f4f4;
$__plum__gray: #878787;
$__midnight__gray: #66676b;
$__plum__black: #1f1f1f;
$__blue__gray: #f4f4f5;
$__fire__red: #f83f37;
$__grey__color:#8d8d8d;

:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #fd7e14;
    --bs-yellow: #ffc107;
    --bs-green: #198754;
    --bs-teal: #20c997;
    --bs-cyan: #0dcaf0;
    --bs-white: #fff;
    --bs-gray: #6c757d;
    --bs-gray-dark: #343a40;
    --bs-gray-100: #f8f9fa;
    --bs-gray-200: #e9ecef;
    --bs-gray-300: #dee2e6;
    --bs-gray-400: #ced4da;
    --bs-gray-500: #adb5bd;
    --bs-gray-600: #6c757d;
    --bs-gray-700: #495057;
    --bs-gray-800: #343a40;
    --bs-gray-900: #212529;
    --bs-primary: $__primarycolor;
    --bs-secondary: $__secondarycolor;
    --bs-success: #198754;
    --bs-info: #0dcaf0;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-light: #f8f9fa;
    --bs-dark: #212529;
    --bs-primary-rgb: 13, 110, 253;
    --bs-secondary-rgb: 108, 117, 125;
    --bs-success-rgb: 25, 135, 84;
    --bs-info-rgb: 13, 202, 240;
    --bs-warning-rgb: 255, 193, 7;
    --bs-danger-rgb: 220, 53, 69;
    --bs-light-rgb: 248, 249, 250;
    --bs-dark-rgb: 33, 37, 41;
    --bs-white-rgb: 255, 255, 255;
    --bs-black-rgb: 0, 0, 0;
    --bs-body-color-rgb: 33, 37, 41;
    --bs-body-bg-rgb: 255, 255, 255;
    --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    --bs-body-font-family: var(--bs-font-sans-serif);
    --bs-body-font-size: 1rem;
    --bs-body-font-weight: 400;
    --bs-body-line-height: 1.5;
    --bs-body-color: #212529;
    --bs-body-bg: #fff;
}


.primary {
	background-color:$__primarycolor !important;
}
.secondary{
	background-color:$__secondarycolor !important;
}

.bg-base {
    background-color: $__basecolor !important;
}
