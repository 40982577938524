.hero-section{
    position: relative;
    .home-banner {
        background-image: url(../../../assets/img/hero/hero-slide-1.jpg);
        background-size: $__cover;
        background-position: center;
        height: $__full__height;
        width: $__full__width;
        @include margin-padding(0,0);
        z-index: -1;
        @include position($__absolute,0,null,null,0);
    }
}

.home-login {

    .viewall {
        padding: 40px 0 40px;
        text-align: left;

        span {
            color: $__basecolor;
            font-size: 60px;
            line-height: 3.5rem;
            font-weight: 800;

            .first-word {
                color: $__primaryred;
            }
        }

    }


    &__form {
        //background-image: url(../../../assets/img/home/home-login-form-bg.jpg);
        padding: 3rem 8rem;
        background-size: cover;
        color: $__basecolor;
        display: flex;
        justify-content: center;

        @include media-breakpoint-down(lg) {
            padding: 5rem 5rem;
        }

        @include media-breakpoint-down(md) {
            padding: 4rem 4rem;
        }

        @include media-breakpoint-down(sm) {
            padding: 2rem 2rem;
        }

        a {
            color: $__white;
            text-decoration: none;
        }

        .card-body {
            max-width: 700px;
            max-height: 500px;
            padding: 0rem 6rem;
            //background: rgba(255, 255, 255, 0.18);
            //border-radius: 16px;
            //box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
            //backdrop-filter: blur(8.4px);
            //-webkit-backdrop-filter: blur(8.4px);

            .btn-primary {
                background-color: #d52246;
                border: 1px solid #d52246;
            }
        }
    }

    &__features {
        //background-color: $__basecolor;
        color: $__basecolor;

        h3 {
            font-size: 2.5rem;
            padding-top: 2rem;
            padding-bottom: 2rem;
            .first-word {
                color: $__primaryred;
            }
        }

        .service-section .icon-box {
            margin-bottom: 20px;
            padding: 10px 0;
            border-radius: 6px;
        }
        .service-section .icon-box:hover .service-title a {
            color: $__primaryred;
        }
        .service-section .icon-box .service-icon {
            float: left;
            color: $__primaryred;
            font-size: 20px;
        }
        .service-section .icon-box .service-title {
            margin-left: 70px;
            font-weight: 700;
            margin-bottom: 15px;
            font-size: 18px;
            line-height: 1.2;
        }
        .service-section .icon-box .service-title a {
            color: #fff;
            transition: 0.3s;
            text-decoration: none;
        }
        .service-section .icon-box .service-para {
            margin-left: 50px;
            line-height: 20px;
            font-size: 14px;
        }
        .service-section .service-main-heading {
            color: #fff;
            padding: 0;
            margin-bottom: 20px;
            line-height: 1;
            font-size: 60px;
            font-weight: 600;
        }
    }

}

.section-search {
    float: $__left;
    width: $__full__width;
    text-align: $__center;
    @include margin-padding(250px 0 250px,0);
    h3 {
        font-size: $__font__size__40;
        color: $__whitecolor;
        font-weight: $__bold;
        @include margin-padding(0 0 10px,null);
        span {
            color: $__primarycolor;
        }
        @include respond-below(custom768) {
            font-size: $__font__size__30;
        }
    }
    p {
        font-size: $__font__size__22;
        color: $__whitecolor;
        font-weight: $__regular;
        @include margin-padding(0,0);
    }
    .search-box {
        width:$__full__width;
        background: $__whitecolor;
        @include rounded(40px);
        @include margin-padding(35px 0 0,10px 10px 10px 15px);
        display: $__table;
        form {
            float: $__left;
            width: $__full__width;
            .search-input {
                width: 53%;

                input {
                    outline: none !important;
                }

                &.line{
                width: 53%;



                &::before {
                    @include position($__absolute,50%,0,null,null);
                    width: 1px;
                    height: 60px;
                    background: $__goose-gray;
                    content: "";
                    z-index: 1;
                    @include margin-padding(-30px 0 0,0);
                    @include respond-below(custom991) {
                        width: 0;
                    }
                }
                @include respond-below(custom991){
                    &.line {
                        width: $__full__width;
                    }
                }

            }

                i.bficon {
                    right: $__inherit;
                    left: 25px;
                    z-index: 1;
                }
                input, a {
                    height: 57px;
                    @include margin-padding(0,0 40px 0 60px);
                    border: 0;
                }
                i {
                    @include position($__absolute,50%,15px,null,null);
                    @include transform(translateY(-50%));
                    color: $__granite;
                    font-size: $__font__size__18;
                }
            }
            .search-input {
                float: $__left;
                display: table-cell;
                vertical-align: $__middle;
                position: $__relative;

                .input {
                    height: 57px;
                    @include margin-padding(0,0 40px 0 60px);
                    border: 0;
                }
            }
            .search-input {
                width: 28%;
                position: relative;

                .dp__icon {
                    //display: none;
                }

                .form-guests {
                    display: flex;
                    width: 100%;

                    span {
                        width: 100%;
                        display: flex;
                        height: 57px;
                        cursor: pointer;
                        flex: 1;
                        align-items: center;
                        //justify-content: center;
                        padding-left: 3.5rem;
                    }

                }


                &__guests-box {
                    position: absolute;
                    padding: 2rem;
                    background-color: $__white;
                    z-index: 1000;
                    border-radius: 7px;

                    -webkit-box-shadow: 0px 3px 30px -19px rgba(66, 68, 90, 1);
                    -moz-box-shadow: 0px 3px 30px -19px rgba(66, 68, 90, 1);
                    box-shadow: 0px 3px 30px -19px rgba(66, 68, 90, 1);

                    top: 4.5rem;
                    right: 0;

                    .guests-box-item {
                        display: flex;
                        align-items: center;

                        button {
                            border: 1px solid #f58220;
                            background: none;
                            border-radius: 50%;
                            width: 30px;
                            height: 30px;
                        }

                        label {
                            margin-right: 1rem;
                        }

                        input {
                            width: 65px;
                            border: 1px solid $__primarycolor;
                            margin: 1rem;
                            height: auto;
                            padding: 0rem 1rem;
                            text-align: center;
                        }



                        /* Chrome, Safari, Edge, Opera */
                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                        }

                        /* Firefox */
                        input[type=number] {
                            -moz-appearance: textfield;
                        }
                    }

                    .guests-box-item-age {
                        select {
                            border: 1px solid #f58220;
                            background: none;
                            border-radius: 6px;
                            padding: 0.4rem;
                            margin: 0.4rem;
                        }

                    }
                }

            }
            .search-btn {
                float: $__right;
                width: 15%;
                .btn {
                    width: $__full__width;
                    border: 1px solid $__primarycolor;
                    background: $__primarycolor;
                    @include rounded(40px);
                    color: $__whitecolor;
                    font-size: $__font__size__18;
                    font-weight: $__bold;
                    @include margin-padding(0,15px 20px);
                }
            }
        }
        @include respond-below(custom991){
            .search-btn {
                float: $__left !important;
                width: $__full__width !important;
                padding: 0;
            }
            .search-input {
                width: $__full__width !important;
            }
        }
    }
    .search-cat {
        margin-top: 30px;
        text-align: $__center;
        i {
            color: $__saffron;
            margin-right: 5px;
        }
        span {
            float: $__none;
            display: $__inline__block;
            color: $__whitecolor;
            font-size: $__font__size__18;
            line-height: 29px;
            margin-right: 25px;
        }
        a {
            display: $__inline__block;
            font-size:$__font__size__15;
            color: $__whitecolor;
            @include margin-padding(0 5px,8px 25px);
            border: 1px dashed $__whitecolor;
            @include rounded(25px);
            opacity: 0.8;
            &:hover {
                background: $__primarycolor;
                color: $__whitecolor;
                border-color: $__primarycolor;
                opacity: 1;
            }
            @include respond-below(custom991){
                margin: 10px 5px;
            }
        }
    }
}
.category-section {
    @include margin-padding(0,40px 0);
    position: relative;
    z-index: 100;

    .container {
        position: relative;
        z-index: 100;

        .row {
            position: relative;
            z-index: 100;
        }
    }
}
.content {
    @include margin-padding(0,40px 0);
}
.heading {
    @include margin-padding(0,40px 0 50px 30px);
    margin-bottom: 20px;
    background: url(../../../assets/img/title-image.png) no-repeat left center;
    h2 {
        font-size: $__font__size__30;
        color: $__basecolor;
        font-weight: $__bold;
        margin-bottom: 10px;
    }
    span {
        font-size: $__font__size__18;
        color: $__granite;
    }
}
.viewall {

    text-align: $__left;
    @include margin-padding(0,40px 0 0);
    h4 {
        @include margin-padding(0,0);
        a {
            color: $__dark-gray;
            font-size: $__font__size__18;
            font-weight: $__medium;
            text-transform: $__uppercase;
            @extend %inline-flex;
            @extend %align-items-center;
        }
    }
    span {
        color: $__basecolor;
        font-size: 37px;
        line-height: 3.5rem;
        font-weight: 800;
        @include respond-below(custom992) {
            display: $__none;
        }

        .first-word {
            color: $__primarycolor;
        }
    }
    @include respond-below(custom992) {
        text-align: $__left;
        padding: 0 0 30px;
    }
}
.cate-widget {
    position: $__relative;
    overflow: $__hidden;
    margin-bottom: 30px;
    @include rounded(15px);
    &:before {
        background: rgba(0,0,0,0.2);
        content: "";
        width: $__full__width;
        height: $__full__height;
        z-index: 1;
        @include position($__absolute,0,null,null,0);
    }
    &:hover img {
        @include transform(scale(1.1));
    }
    img {
        @include transform(translateZ(0));
        @include transition(all 0.3s);
        width: $__full__width;
    }
    .cate-title {
        @include position($__absolute,null,null,15px,15px);
        z-index: 1;
        h3 {
            margin: 0;
            font-size: $__font__size__17;
            font-weight: $__regular;
            color: $__whitecolor;
            span {
                color: $__white;
                display: $__inline__block;
                @include margin-padding(0,8px 12px);
                @include rounded(20px);
                i {
                    color: $__primarycolor;
                    font-size: $__font__size__17;
                    margin-right: 3px;
                }
            }
        }
    }
    .cate-count {
        @include position($__absolute,null,15px,15px,null);
        z-index: 1;
        color: $__whitecolor;
        font-size: $__font__size__15;
    }
}
.popular-services {
    background-color: $__light__white;
    @include margin-padding(0,40px 0);
}
.service-widget {
    background-color: $__whitecolor;
    border: 1px solid $__light__white;
    @include rounded(15px);
    margin-bottom: 30px;
    position: $__relative;
    @include transition(all .3s);
    @include box-shadow(null, 0, 1px, 4px, null, rgba(0, 0, 0, .05));
    &:hover .fav-icon {
        opacity: 1 !important;
        visibility: $__visible !important;
        right:30px !important;
        @include transform(translate3d(0, 0, 0));
        @include transition(all 0.3s ease);
    }
    .service-img {
        position: $__relative;
        overflow: $__hidden;
        z-index: 1;
        &::before {
            background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%);
            content: "";
            @include position($__absolute,null,null,0,0);
            width: $__full__width;
            height: 70px;
            z-index: 1;
        }
        .serv-img {
            @include rounded(15px 15px 0 0);
            @include transform(translateZ(0));
            @include transition(all 0.3s);
            width: $__full__width;
        }
        .fav-btn {
            @include position($__absolute,15px,15px,null,null);
            z-index: 1;
            .fav-icon {
                @extend %align-items-center;
                @extend %inline-flex;
                @extend %justify-content-center;
                @include position($__absolute,0,-30px,null,null);
                @include transform(translate3d(100%, 0, 0));
                @include transition(all 0.3s ease);
                background-color: $__white;
                width: 30px;
                height: 30px;
                font-size: $__font__size__20;
                @include rounded(5px);
                color: $__light__battle__gary;
                opacity: 0;
                visibility:$__hidden;
                z-index: 99;
                &.favourite {
                    animation: size .4s;
                    color: $__primarycolor;
                }
            }
        }
        .item-info {
            @include position($__absolute,null,null,0,0);
            @include margin-padding(0,0 15px 15px);
            width: $__full__width;
            z-index: 1;
            .service-user {
                font-size: $__font__size__20;
                line-height: 28px;
                color: $__whitecolor;
                font-weight: $__bold;
                float: $__left;
                img {
                    width: 30px !important;
                    height: 30px;
                    @include rounded(50%);
                    margin-right: 8px;
                    display: $__inline__block !important;
                }
            }
            .cate-list {
                float: right;
                .bg-yellow {
                    background: $__orangecolor;
                }
                a {
                    @include rounded(15px);
                    @include margin-padding(0,2px 15px);
                    color: $__whitecolor;
                    font-size: $__font__size__13;
                    overflow: $__hidden;
                    line-height: 22px;
                    position: $__relative;
                    display: $__inline__block;
                }
            }
        }
    }
    .service-content {
        @include margin-padding(0,15px);
        .title {
            font-size: $__font__size__18;
            font-weight: $__medium;
            margin-bottom: 10px;
            a {
                display: $__inline__block;
                color: $__basecolor;

                &:hover {
                    color: $__primarycolor;
                }
            }
        }
        .rating {
            color: $__smokey-gray;
            font-size: $__font__size__18;
            margin-bottom: 15px;
            .filled {
                color: $__orangecolor;
            }
            i {
                color: $__light-goose-gray;
            }
        }
        .ser-contact {
            @extend %display-flex;
            overflow: $__hidden;
            @extend %align-items-center;
            i {
                font-size: $__font__size__12;
                line-height: 26px;
                color: $__dark-gray;
                text-align: $__center;
                width: 27px;
                height: 27px;
                border: 1px dashed $__dark-gray;
                @include rounded(50%);
            }
            span {
                overflow: $__hidden;
                text-overflow: $__ellipsis;
                white-space: $__nowrap;
                width: calc(100% - 30px);
                display: $__block;
            }
        }
        .ser-location {
            @extend %align-items-center;
            @extend %display-flex;
            overflow: $__hidden;
            text-align: $__left;
            span {
                overflow: $__hidden;
                text-overflow: $__ellipsis;
                white-space: $__nowrap;
                width: calc(100% - 30px);
                display: $__block;
            }
            i {
                font-size:$__font__size__12;
                line-height: 26px;
                color: $__dark-gray;
                text-align: $__center;
                width: 27px;
                height: 27px;
                border: 1px dashed $__dark-gray;
                @include rounded(50%);
            }
        }
    }
}
.service-carousel  {
    .owl-dots{
        .owl-dot{
            .active span {
                border-color: $__transparent;
                width: 30px;
                height: 12px;
                @include transition(all 0.35s);
            }
        }
    }
}
.how-work {
    @include margin-padding(0,40px 0);
}
.howitworks {
    text-align: $__center;
    background: url(../../../assets/img/title-image2.png) no-repeat center center;
    padding-left: 0;
}
.howwork {
    float: $__left;
    width: $__full__width;
    text-align: $__center;
    @include margin-padding(0,0 30px);
    .iconround {
        float: $__none;
        display: $__inline__block;
        font-size: $__font__size__65;
        background: $__whitecolor;
        color: $__primarycolor;
        width: 120px;
        height: 120px;
        text-align: $__center;
        @include box-shadow(null,0,5px, 10px, null, rgba(0, 0, 0, .10));
        position: $__relative;
        @include rounded(50%);
        .steps {
            width: 40px;
            height: 40px;
            background: $__primarycolor;
            font-size:$__font__size__16;
            color: $__whitecolor;
            text-align: $__center;
            line-height: 40px;
            @include rounded(50%);
            @include position($__absolute,15px,null,null,-15px);
        }
    }
    h3 {
        float: $__left;
        width: $__full__width;
        font-size: $__font__size__20;
        font-weight: $__bold;
        color: $__dark__ash;
        @include margin-padding(30px 0 12px 0,0);
    }
    p {
        float: $__left;
        width: $__full__width;
        font-size: $__font__size__15;
        color: $__grey__color;
        line-height: 24px;
        @include margin-padding(0,0);
        @include margin-padding(5px 0 0,0 20px);
    }
}
.app-set {
    background: $__dark__light url(../../../assets/img/bgapp.png) no-repeat right;
    @include margin-padding(0,25px 0);
    .downlaod-set ul {
        list-style: $__none;
        @extend %flex-wrap;
        @include margin-padding(0,0);
        @extend %display-flex;
        @extend %align-items-center;
        li {
            @include margin-padding(0 10px 15px 0,0);
            img {
                max-width: 180px;
                height: 60px;
            }
        }
    }
}
@include respond-below(custom991) {
.appimg-set{
    display: $__none;
    }
}
