.notcenter {
    float: $__left;
    width: $__full__width;
    &:first-child {
        padding-top: 0;
    }
    .notificationlist {
        float: $__left;
        width: $__full__width;
        @include margin-padding(null,20px 0);
        border-bottom: 1px solid $__gray__powder;
        position: $__relative;
        .noti-contents {
            margin-left: 15px;
            color: $__candycolor;
            @extend %display-flex;
            @extend %flex-column;
            @extend %justify-content-center;
            h3 {
                float: $__left;
                width: $__full__width;
                font-size: $__font__size__14;
                color: $__taupe__black;
                margin: 0;
            }
            span {
                float: $__left;
                width: $__full__width;
                font-size: $__font__size__13;
                color: $__dark__gray;
                margin-top: 6px;
            }
        }
    }
}
.topnav-dropdown-header{
    font-size: $__font__size__14;
    height: 40px;
    line-height: 40px;
    @include margin-padding(null,0 15px);
    border-bottom: 1px solid $__cloud__gray;
    .notification-title {
        color: $__gray;
        display: $__block;
        float: $__left;
        font-size: $__font__size__14;
    }
    .clear-noti {
        color: $__fire__red;
        float: $__right;
        font-size: $__font__size__12;
        text-transform: $__uppercase;
    }
}
.notifications {
    .noti-content {
        height: 290px;
        width: 350px;
        overflow-y: $__auto;
        position: $__relative;
    }
    .notification-list {
        @extend %ul_reset;
    }
    li {
        margin-top: 0;
        border-bottom: 1px solid $__dark__light;
        a {
            display: $__block;
            @include margin-padding(0,10px 15px);
            border-radius: 2px;
        }
    }
    .media{
        &:last-child {
            border-bottom: $__none;
        }
        .avatar {
            margin-right: 10px;
            img {
                width: $__full__width;
                height:$__full__height;
                object-fit: $__cover;
            }
        }
    }
    .noti-details {
        color: $__frog__blue;
        margin-bottom: 0;
        font-size: $__font__size__15;
        line-height: 22px;
        .noti-title {
            color: $__gray;
        }
    }
    .notification-time {
        font-size: $__font__size__12;
        line-height: 1.35;
        color: $__sandstone;
    }
}

.topnav-dropdown-footer {
    font-size: $__font__size__14;
    height: 40px;
    line-height: 40px;
    @include margin-padding(null,0 15px);
    border-top: 1px solid $__cloud__gray; 
    a {
        display: $__block;
        text-align: $__center;
        color: $__gray;
    }
}