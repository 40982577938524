.nav-tabs {
    &.menu-tabs{
        @include margin-padding(0 0 30px ,null);
        .nav-item {
            @include margin-padding(null ,0 0 0 2.25rem);
            .nav-link {
                font-weight: $__bold;
                position: $__relative;
                border: $__none;
                outline: $__none;
                background: $__transparent;
                color: $__dark__gray;
                @include margin-padding(null , 0 0 1rem);
                @extend %align-items-center;
                @extend %inline-flex;
                @include position($__relative, null, null, null, null);
                &::after {
                    height: 3px;
                    background: $__primarycolor;
                    opacity: 0;
                    @include position($__absolute, null, 0, 0, 0);
                    content: "";
                }
                .badge {
                    margin-left: 0.25rem;
                }
            }
           &.active{
            .nav-link{
                color: $__primarycolor;
                &::after {
                    opacity: 1;
                }
            }
           }
           @include respond-below(custom767) {
                @include margin-padding(20px 0 0,0 0 0 1.25rem);
            }
        }
    }
}
.service-tabs {
    li{
        +li{
            margin-left: 10px;
        }
    }
    
}
.nav-pills{
    .nav-link{
        color: $__primarycolor;
        background: $__whitecolor;
        border: 1px solid $__primarycolor;
        @include rounded(0.25rem);
        &.active{
            color: $__whitecolor;
            background-color: $__primarycolor;
            border: 1px solid $__primarycolor;
        }
    }
}
.tab-content {
    @include margin-padding(null ,20px 0 0);
}
.card-title {
    margin-bottom: 15px;
}
.sidebar-widget {
    .service-amount {
        color: $__basecolor;
        font-size: $__font__size__36;
        font-weight: $__bold;
        line-height: 40px;
        margin-bottom: 20px;
    }
}
.about-author {
    min-height: 80px;
    .about-provider-img {
        background-color: $__whitecolor;
        height: 80px;
        overflow: $__hidden;
        width: 80px;
        @include position($__absolute, null, null, null, null);
    }
    .provider-details {
        margin-left: 100px;
        .ser-provider-name {
            display: $__inline__block;
            margin-bottom: 5px;
            color: $__dark__blue;
            font-size:$__font__size__16;
            font-weight: $__semibold;
        }
        .last-seen {
            margin-bottom: 5px;
            @extend %align-items-center;
            @extend %display-flex;
            .online {
                color: $__success__light;
                margin-right: 5px;
                font-size: $__font__size__12;
            }
        }
    }
    
}
.available-widget{
    ul {
        @include margin-padding(0,0);
        list-style-type: $__none;
        li {
            color: $__granite;
            overflow: $__hidden;
            text-align: $__right;
            @include margin-padding(null,10px 0);
            &:first-child{
                padding-top: 0;
            }
            span {
                float: $__left;
            }
        }
    }
}
.service-fields {
    textarea.service-desc {
        min-height: 200px;
    }
}
.service-upload {
    border: 2px dashed $__light-gray;
    text-align: $__center;
    background-color: $__white;
    position: $__relative;
    @include margin-padding(0 0 30px, 70px 0);
    @include rounded(0.25rem);
    i {
        font-size: $__font__size__50;
        color: $__granite;
    }
    span {
        font-size: $__font__size__15;
        color: $__granite;
        margin-top: 14px;
        display: $__block;
    }
    input[type="file"] {
        width: $__full__width;
        height: $__full__height;
        opacity: 0;
        cursor: $__pointer;
        @include position($__absolute, 0, null, null, 0);
    }
}
.service-images {
    margin-bottom: 30px;
}
.upload-wrap {
    @extend %display-flex;
    @extend %flex-wrap;
    li {
        margin: 10px;
        .upload-images {
            position: $__relative;
            img {
                height: 60px;
                width: $__auto;
                @include rounded(4px);
            }
        }
    }
}
.nav-tabs{
    &.nav-tabs-solid  {
        border: 0;
        li {
            a {
                border-color: $__transparent;
                color: $__gray;
                &.active{
                    background-color: $__primarycolor;
                    border-color: $__primarycolor;
                    color: $__white;
                }
            }
        }
    }
}