.account-modal {
    .close {
        background-color: $__ash__gray;
        border: $__ash__gray;
        color:$__white;
        font-size: $__font__size__17;
        height: 20px;
        line-height: 20px;
        opacity: 1;
        width: 20px;
        z-index: 99;
        @include margin-padding(0,0);
        @include position($__absolute,10px,10px,null,null);
        @include rounded(50%);
    }
}
.login-header {
    @include margin-padding(0 0 20px,0);
    h3 {
        font-size: $__font__size__24;
        text-align: $__center;
        @include margin-padding(0 0 3px,null);
    }
}
.form-group {
    @include margin-padding(0 0 1rem,null);
}
.login-or {
    color: $__dark__cloud;
    @include margin-padding(20px 0,10px 0);
    position: $__relative;
    .or-line {
        background-color: $__light__cloud;
        height: 1px;
        display: $__block;
        @include margin-padding(0,0);
    }
    .span-or {
        background-color: $__white;
        display: $__block;
        @include margin-padding(0 0 0 -20px,0);
        @include position($__absolute,-3px,50%,null,null);
        text-align: $__center;
        width: 42px;
    }

}
.btn-facebook {
    background-color: $__lapis__blue;
    color: $__white;
    font-size: $__font__size__13;
    @include margin-padding(null,8px 12px);
}
.btn-google {
    background-color: $__dark__lava__red;
    color: $__white;
    font-size: $__font__size__13;
    @include margin-padding(null,8px 12px);
}
.dont-have {
    color: $__night;
    @include margin-padding(20px 0 0,null);
    font-size: $__font__size__13;
}

.login-container {
    display: flex;
    height: 100vh;
    align-items: center;

    .login-box {
        display: flex;
        width: 100%;
    }
}
