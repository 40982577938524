.admin-panel {
    .main-wrapper {
        background-color: $__whitecolor;
        height: $__auto;
        @include margin-padding(null ,20px 20px 20px 0);
        @include respond-below(custom992) {
            @include margin-padding(null ,20px);
        }
        .header {
            background: $__whitecolor;
            z-index: 99;
            @include box-shadow(null, 1px, 6px, 14px, null, rgba(55,73,72,2%));
            @include rounded(10px);
            @include position($__fixed,50px,50px,null,310px);
            @include transition(all 0.3s ease);
            .mobile_btn {
                display: $__none;
                float: $__right;
                @include respond-below(custom992) {
                    color: $__gray;
                    cursor: $__pointer;
                    font-size: $__font__size__26;
                    height: 60px;
                    text-align: $__center;
                    z-index: 10;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    @include margin-padding(null ,0px 15px);
                    @include position($__absolute,14px,120px,null,null);
                }

                @include respond-below(custom575) {
                    @include position($__absolute,14px,-38%,null,null);
                }
            }

            .nav-chat-icon {
                position: absolute;
                top: 20px;
                right: 400px;
                color: white;
                font-size: 1.5rem;

                @include respond-below(custom575) {
                    right: auto;
                    left: 7%;
                }
            }


            &.fixed-header {
                padding: 1rem;
                background-color: $__primarycolor;
                @include rounded(0px);
                @include position(null,0,0,null,280px);
                @include margin-padding(0 ,null);
                @include transition(all 0.3s ease);
                @include respond-below(custom992) {
                    left: 0;
                    width:$__full__width;
                    height: 80px;
                }
            }
            .header-left {
                float: $__left;
                height: 60px;
                position: $__relative;
                text-align: $__center;
                width: 280px;
                z-index: 1;
                display: $__none;
                @include margin-padding(null ,0 20px);
                @include transition(all 0.3s ease);
                @include respond-below(custom992) {
                    position: $__absolute;
                    width: $__full__width;
                    display: $__block;
                }
                @include respond-below(custom767) {
                    @include margin-padding(null ,0 15px);
                }
            }
            .logo{
                line-height: 60px;
                &.logo-small {
                    display: $__none;
                    @include respond-below(custom992) {
                        display: $__block;
                    }
                    @include respond-below(custom992) {
                        img{
                            max-height: 36px;
                            height: 36px;
                        }
                    }
                }
            }
            #toggle_btn {
                color: $__gray;
                float: $__left;
                font-size: $__font__size__26;
                height: 60px;
                @include margin-padding(null ,0 20px);
                @extend %justify-content-center;
                @extend %align-items-center;
                @extend %inline-flex;
                @include respond-below(custom992) {
                    display: $__none;
                }
            }
            .user-menu {
                float: $__left;
                position: $__relative;
                z-index: 99;
                @include margin-padding(null ,12px 0);
                .dropdown-toggle{
                    &::after{
                        display: $__none;
                    }
                }
                li{
                    a {
                        color: $__gray;
                        font-size: $__font__size__14;
                        @include margin-padding(null ,0px 15px);
                        @include respond-below(custom992) {
                            @include margin-padding(null ,0px 5px);
                        }
                        .user-img {
                            display: $__inline__block;
                            margin-right: 3px;
                            position: $__relative;
                        }
                    }
                    &.noti-dropdown {
                        a{
                            position: $__relative;
                            z-index: 1;
                            padding: 6px !important;
                            min-width: 36px;
                            @extend %inline-flex;
                            &::before {
                                z-index: -1;
                                height: 20px;
                                width: 20px;
                                content: '';
                                background-color: $__light__cloud;
                                opacity: 0;
                                @include transform-origin(50% 50%);
                                @include transform(translate(-50%, -50%));
                                @include position($__absolute,50%,null,null,50%);
                                @include rounded(50%);
                                @include transition(all 0.3s ease);
                            }
                            i {
                                font-size: $__font__size__24;
                            }
                            .badge {
                                background-color: $__green;
                                display: $__block;
                                font-size: $__font__size__10;
                                font-weight: $__bold;
                                height: 10px;
                                width: 10px;
                                border: 2px solid $__white;
                                line-height: $__unset;
                                @include position($__absolute,7px,7px,null,null);
                                @include rounded(50px);
                                @include margin-padding(null ,0);
                            }
                        }
                    }
                }
                .noti-dropdown{
                    @include respond-below(custom575) {
                        display: $__none;
                    }
                }
            }
            @include respond-below(custom992) {
                @include position(null,null,null,null,50px);
            }
            @include respond-below(custom575) {
                @include position(null,35px,35px,null,35px);
            }
        }
    }
    .mini-sidebar{
        .sidebar {
            width: 90px;
            .sidebar-menu{
                ul{
                    li{
                        a{
                            span{
                                display: $__none;
                                opacity: 0;
                                @include transition(all 0.3s ease);
                            }
                        }
                    }
                }
            }
        }
        @include respond-above(custom992) {
            .main-wrapper  {
                .header {
                    @include position(null,null,null,null,120px);
                }
            }
        }

        .page-wrapper {
            @include margin-padding(0 0 0 90px ,null);
        }
        &.expand-menu {
            .sidebar {
                width: 280px;
                .sidebar-menu{
                    ul{
                        li{
                            a{
                                span{
                                    display: $__block;
                                    opacity: 1;
                                    @include transition(all 0.3s ease);
                                }
                            }
                        }
                    }
                }
            }
            .page-wrapper {
                @include margin-padding(0 0 0 280px,null);
            }
            @include respond-above(custom992) {
                .main-wrapper  {
                    .header {
                        @include position(null,null,null,null,310px);
                    }
                }
            }
        }
    }
}
