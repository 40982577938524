.admin-panel {
    .login-page {
        background-color: $__dark__light;
        position: $__relative;
        @include margin-padding(-20px -20px -20px 0, 0);
        &::before {
            content: "";
            width: $__full__width;
            z-index: -1;
            pointer-events: $__none;
            height: $__half_height_window;
            background-color: $__primarycolor;
            @include rounded(0 0 2.5rem 2.5rem);
            z-index: 0;
            @include position($__absolute,0,null,null,0);
        }
        .login-body {
            z-index: 10;
            position: $__relative;
            min-height: $__full_height_window;
            @extend %justify-content-center;
            @extend %display-flex;
            @extend %align-items-center;
            @include margin-padding(null,15px);
            .loginbox {
                background-color: $__whitecolor;
                max-width: 500px;
                width: $__full__width;
                @include box-shadow(null, 0px, 0px, 12px, 0, rgba(55,73,72,2%));
                @include margin-padding(null ,40px);
                @include rounded(10px);
            }
            .account-logo {
                img {
                    max-height: 80px;
                    width: $__auto;
                }
            }
            .login-header {
                margin-bottom: 20px;
                h3 {
                    font-size: $__font__size__20;
                    margin-bottom: 0;
                    text-transform: $__uppercase;
                    span {
                        color: $__primarycolor;
                    }
                }
                p {
                    margin-bottom: 0;
                }
            }
            .form-control {
                height: 50px;
            }
        }
        .account-btn {
            font-size: $__font__size__20;
            font-weight: $__medium;
            display: $__block;
            width: $__full__width;
            color: $__whitecolor;
            text-align: $__center;
            @include margin-padding(null ,10px 20px);
            @include rounded(5px);
        }
    }
}
