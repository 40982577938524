.admin-panel {
    .page-wrapper {
        background-color: $__secondarycolor;
        padding-top: 90px;
        min-height: calc(100vh - 40px);
        margin-left: 280px;
        position: $__relative;
        @include transition(all 0.3s ease);
        @include rounded(10px);
        @include respond-below(custom992) {
            @include margin-padding(0,null);
            @include transition(all 0.3s ease);
        }
        @include respond-below(custom575) {
            .delete_review_comment {
                width: $__full__width;
            }
        }
        .content {
            @include margin-padding(null ,1.875rem 1.875rem 0);
            @include respond-below(custom575) {
                @include margin-padding(null ,0.9375rem 0.9375rem 0);
            }
        }
        .page-header {
            margin-bottom: 1.875rem;
            .page-title {
                color: $__gray;
                margin-bottom: 5px;
            }
            .filter-btn {
                border: 0;
                width: 45px;
                height: 45px;
                color: $__primarycolor;
                @extend %justify-content-center;
                @extend %align-items-center;
                @extend %inline-flex;
                @include box-shadow(null, 0px, 0px, 12px, 0, rgba(55,73,72,2%));
                @include rounded(10px);
            }
            .add-button {
                width: 45px;
                height: 45px;
                @extend %justify-content-center;
                @extend %align-items-center;
                @extend %inline-flex;
                @include box-shadow(null, 0px, 0px, 12px, 0, rgba(255,0,128,20%));
                @include rounded(10px);
            }
        }
    }
    .card {
        @include rounded(10px);
        .card-header {
            background-color: $__whitecolor;
            border-bottom: 1px solid $__mild___gray;
            @include margin-padding(0,1rem 1.5rem);
            .card-title {
                margin-bottom: 0;
                font-size: $__font__size__18;
            }
        }
    }
    .card-table {
        .card-body {
            padding: 0;
        }
    }
    .filter-card {
        .form-group {
            margin-bottom: 1.5rem;
            position: $__relative;
            label {
                background: $__whitecolor;
                font-size: $__font__size__12;
                color: $__granite;
                z-index: 1;
                @include position($__absolute,-7px,null,null,10px);
                @include margin-padding(null,0 7px);
            }
            .cal-icon {
                position: $__relative;
                width: $__full__width;
                &:after {
                    color: $__ligt__carbon;
                    content: "\f073";
                    display: $__block;
                    font-family: "Font Awesome 5 Free";
                    font-weight: $__medium;
                    font-size: $__font__size__15;
                    margin: $__auto;
                    @include position($__absolute,10px,15px,null,null);
                }
            }
        }
        .form-control {
            height: 48px;
            &:focus{
                border-color: $__primarycolor;
                outline: 0;
                box-shadow: $__none;
            }
        }
        .select2-container--default {
            .select2-selection--single {
                height: 48px;
                .select2-selection__rendered {
                    line-height: 46px;
                }
            }
        }
        .btn {
            height: 48px;
        }
    }
    .service-header {
        margin-bottom: 30px;
        @extend %display-flex;
        @extend %align-items-center;
        h2 {
            font-weight: $__bold;
        }
        address {
            margin-bottom: 1rem;
            font-style: $__normal;
            line-height: $__inherit;
        }
        .rating {
            list-style: $__none;
            width: $__full__width;
            @include margin-padding(0 0 7px,0);
            i {
                color: $__light-goose-gray;
                &.filled {
                    color: $__orangecolor;
                }
            }
        }
        .service-cate {
            a {
                color: $__white;
                text-transform: $__uppercase;
                background: $__dull-gold;
                font-size: $__font__size__13;
                display: $__inline__block;
                @include rounded(4px);
                @include margin-padding(null,2px 10px);
            }
        }
        .service-amount {
            color: $__primarycolor;
            font-size: $__font__size__36;
            font-weight: $__bold;
            line-height: 40px;
            margin-left: $__auto;
        }
    }
    .provider-info {
        background-color: $__platinum;
        @include margin-padding(null,15px);
        @include rounded(4px);
        a {
            color: $__primary__light;
        }
    }
    .bootstrap-tagsinput {
        display: $__block;
        background: $__white;
        border: 1px solid $__light__goose;
        min-height: 40px;
        width: $__full__width;
        box-shadow: $__none;
        @include margin-padding(null,5px 10px 0 5px);
        @include rounded(5px);
        .tag {
            color: $__black;
            background: $__light__beige !important;
            font-size: $__font__size__15;
            font-weight: $__medium;
            @include margin-padding(0 5px 5px 0,6px);
            @include rounded(0);
        }
    }
}
