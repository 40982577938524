.admin-panel {
    .page-item{
        &.active{
            .page-link {
                background-color: $__primarycolor;
                border-color: $__primarycolor;
            }
        }
    }
}
