.form-control {
  //border: 0;
  box-shadow: none;
  font-size: 13px;
  height: 40px;
  //padding: 1.5rem 1.6rem;
  border-radius: 5px;
  background-color: #f7faff;
  border: 1px solid #e6ecf1;

  &:before {
    font-family: 'FontAwesome';
    color:red;
    position: relative;
    left: -5px;
    content: "\f65e";
  }

}


//.children-count {
//  &:first-child {
//
//      padding-left: 0px;
//
//  }
//
//  &:last-child {
//
//      padding-right: 0px;
//
//  }
//}

.filter-list .filter-title {
  font-size: 13px !important;
  font-weight: 600 !important;
  margin-bottom: 7px !important;
  color: $__basecolor !important;
}

.dp__instance_calendar {
  padding: 1rem;
}

.dp__input {
  border-radius: 5px !important;
  background-color: #f7faff !important;
  border: 1px solid #e6ecf1 !important;
  color: #333333 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.dp__input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #333333 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.dp__input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #333333 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.dp__input::-ms-input-placeholder { /* Microsoft Edge */
  color: #333333 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
}

.hero-section {

  .form-control {
    background-color: transparent !important;
    border: 0px !important;
  }

  .dp__input {
    background-color: transparent !important;
    border: 0px !important;
  }
}
