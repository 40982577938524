.admin-panel {
    .sidebar {
        background-color: $__white;
        margin-top: 0;
        width: 280px;
        z-index: 70;
        @include transition(all 0.3s ease);
        @include position($__fixed,0,null,0,0);
        @include respond-below(custom992) {
            margin-left: -225px;
            width: 225px;
            @include transition(all 0.4s ease);
            z-index: 1041;
        }
        .sidebar-logo {
            @extend %display-flex;
            @include margin-padding(null ,20px 20px 0);
            @extend %justify-content-center;
            img {
                max-height: 46px;
                width: $__auto;
            }
        }
        .sidebar-inner {
            height:$__full__height;
            min-height: 100%;
            @include transition(all 0.3s ease);
            .sidebar-menu {
                @include margin-padding(null ,15px);
                ul {


                    .submenu {
                        ul {
                            li {
                                a.active {
                                    background-color: $__white !important;
                                    color: $__primarycolor !important;
                                }
                            }
                        }
                    }

                    font-size: $__font__size__15;
                    list-style-type: $__none;
                    @include margin-padding(0 ,0);
                    position: $__relative;
                    li {
                        margin-bottom: 5px;
                        position: $__relative;
                        &.active > a {
                            background-color: $__primarycolor;
                            color: $__white;
                        }
                        ul {
                            display: $__none;
                        }
                        a{
                            justify-content: flex-start;
                            position: $__relative;
                            color: $__gray;
                            font-size: $__font__size__16;
                            height: $__auto;
                            @include rounded(10px);
                            @include margin-padding(null ,10px 20px);
                            @extend %align-items-center;
                            @extend %display-flex;
                            @include transition(all 0.3s ease);
                            &:hover {
                                background-color: $__dark__cloud;
                                color: $__black;
                            }
                            i {
                                display: $__inline__block;
                                font-size: $__font__size__20;
                                line-height: 24px;
                                text-align: $__left;
                                vertical-align: $__middle;
                                width: 20px;
                                @include transition(all 0.3s ease);
                            }
                            span {
                                @include transition(all 0.3s ease);
                                display: $__inline__block;
                                margin-left: 10px;
                                white-space: $__nowrap;

                                @include respond-below(custom992) {
                                    font-size: 0.8rem;
                                }

                                &.menu-arrow i {
                                    @include transition(transform 0.15s);
                                    display: $__inline__block;
                                    text-rendering: $__auto;
                                    font-size: $__font__size__18;
                                    -webkit-font-smoothing: antialiased;
                                    -moz-osx-font-smoothing: grayscale;
                                    @include transform(translate(0,0));
                                    @include position($__absolute,10px,20px,null,null);
                                    width: $__auto;
                                }
                            }
                            &.subdrop {
                                background: $__primarycolor;
                                color: $__whitecolor;

                                @include respond-below(custom992) {
                                    font-size: 0.8rem;
                                }

                                .menu-arrow i{
                                    @include transform(rotate(90deg));
                                }
                            }
                        }
                    }
                    ul{
                        a.active {
                            color: $__primarycolor;
                            text-decoration: $__underline;
                        }
                    }

                }
            }

        }
    }
    @include respond-below(custom992) {
        .slide-nav .sidebar {
            margin-left: 0;
        }
        .sidebar-overlay {
            background-color: rgba(0, 0, 0, 0.6);
            display: $__none;
            height: $__full__height;
            width: $__full__width;
            z-index: 1000;
            @include position($__fixed,0,null,null,0);
            &.opened {
                display: $__block;
            }
        }
    }
}
