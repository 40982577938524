.admin-panel {
    .dash-widget-header {
        @extend %display-flex;
        @extend %align-items-center;
        .dash-widget-icon {
            color: $__white;
            font-size: $__font__size__40;
            height: 80px;
            text-align: $__center;
            width: 80px;
            background-color: $__primarycolor;
            @include rounded(10px);
            @extend %inline-flex;
            @extend %justify-content-center;
            @extend %align-items-center;
        }
        .dash-widget-info {
            margin-left: $__auto;
            text-align: $__right;
            h3 {
                margin-bottom: 10px;
                font-size: $__font__size__24;
            }
            h6 {
                margin-bottom: 0;
            }
        }
    }
}
