body{
	font-family: $__body__font__family;
	font-size:$__body__font__size;
	color:$__dark__ash;
}

.admin-panel {

    .container {
        width: $__full__width;
        max-width: $__container__width;
        padding-left: $__grid__padding__left;
        padding-right: $__grid__padding__right;
        margin: $__margin__auto;
    }

    img {
        max-width: $__full__width;
        height: $__auto;
    }

    a {
        outline: none;
        text-decoration: none;
        color: $__primarycolor;
        cursor: pointer;
        @include transition(all 0.2s ease);

        &:hover {
            @include transition(all 0.2s ease);
        }

        &:focus {
            outline: 0;
        }
    }

    p {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    strong {
        font-family: $__bold;
        font-weight: $__normal;
    }

    label {
        display: inline-block;
        margin-bottom: 0.5rem;
    }
}
