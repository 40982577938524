.widget {
    @include margin-padding(0 0 30px,null);
    ul {
        display: $__block;
        border: 0;
        li {
            color: $__slick__arrow;
            a.active {
                color: $__primarycolor !important;
                border: 0;
            }
            a {
                color: $__granite;
                @include margin-padding(null,0);
                border: 0 !important;
                display: $__inline__block;
                i {
                    margin-right: 10px;
                    font-size: $__font__size__20;
                    min-width: 25px;
                }
            }
            +li{
                margin-top: 15px;
            }
        }
    }

}
.widget-title {
    margin-bottom: 20px;
}
.dash-widget {
    @include rounded(10px);
    @include margin-padding(0 0 30px,20px);
    @extend  %justify-content-between;
    @extend  %display-flex;
    @extend  %align-items-center;
    &.dash-bg-1 {
        background-color: $__primarycolor;
    }
    &.dash-bg-2 {
        background-color: $__night;
    }
    &.dash-bg-3 {
        background-color: $__orangecolor;
    }
    .dash-widget-icon {
        width: 90px;
        height: 90px;
        border: 2px solid $__white;
        text-align: $__center;
        line-height: 90px;
        color: $__white;
        font-size: $__font__size__20;
        font-weight: $__medium;
        flex: 0 0 90px;
        margin-right: 10px;
        @include rounded(50%);
    }
    .dash-widget-info {
        text-align: $__right;
        span {
            color: $__white;
            font-size: $__font__size__18;
            font-weight: $__medium;
            margin-bottom: 0;
        }
    }
}
.sp-plan-action {
    height: $__full__height;
    border-top: 0;
    border-left: 1px solid $__light-silver;
    @extend %justify-content-center;
    @extend %flex-column;
    @extend %flex-wrap;
    @extend %align-items-center;
    @extend %display-flex;
}
.bookings {
    .booking-list {
        background-color: $__white;
        border: 1px solid $__light-silver;
        @include rounded(4px);
        @extend %display-flex;
        @extend %flex-wrap;
        @include margin-padding(0 0 20px,20px);
        @extend %align-items-center;
        @include respond-below(custom768) {
            .booking-widget {
                -ms-flex-direction: column;
                flex-direction: column;
            }
        }
    }
    .booking-widget {
        @extend %display-flex;
        @include margin-padding(0 auto 0 0,null);
        text-align: $__left;
        flex: 0 0 calc(100% - 245px);
        flex: 2;
        .booking-img {
            @include margin-padding(0 15px 0 0,null);
            flex: 0 0 120px;
            max-width: 120px;
            img {
                @include rounded(4px);
                height: 120px;
                object-fit: $__cover;
                width: 120px;
            }
        }
        .booking-det-info{
            h3 {
                font-size: $__font__size__18;
                text-overflow: $__ellipsis;
                white-space: $__nowrap;
                overflow: $__hidden;
                font-weight: $__bold;
                a {
                    color: $__basecolor;
                    white-space: normal;
                }
            }
            li {
                @extend %flex-wrap;
                @extend %display-flex;
                color: $__granite;
                span {
                    width: 120px;
                    display: $__inline__block;
                    font-weight: $__medium;
                    color: $__black;
                    flex: 0 0 120px;

                }
                .badge {
                    color: $__white;
                    width: $__auto;
                    flex: 0 0 auto;
                    margin-left: 5px;
                    font-weight: $__medium;
                    height: 20px;
                    @include margin-padding(null,4px 9px);
                }

            }
            @include respond-below(custom768) {
                 li {
                    color: $__granite;
                    @extend %flex-wrap;
                    span {
                        width: $__full__width;
                        flex: 0 0 100%;
                    }
                }
            }
        }
    }
    .booking-action {
        flex: 0 0 245px;
        flex: 1;
        @extend %align-items-center;
        @extend %inline-flex;
        @extend %jusflex-end;
        @extend %flex-wrap;
        @include respond-below(custom992) {
            flex: 0 0 100%;
            margin-top: 20px;
        }
        a {
            font-size: $__font__size__13;
            @include margin-padding(3px 0,null);
            min-width: 74px;
            +a{
                @include margin-padding(3px 0 3px 5px,null);
            }
        }
    }
}
.user-image {
    @include rounded(18px);
    width: 120px;
    @include margin-padding(0 15px 0 0,null);
}
.jstinput {
    position: $__relative;
    float: $__left;
    width: $__full__width;
    a {
        float: $__none;
        display: $__inline__block;
        background: $__primarycolor;
        color: $__white;
        font-size: $__font__size__14;
        @include margin-padding(null , 5px 20px);
        @include rounded(4px);
    }
}
.form-group {
    @include margin-padding(0 0 15px,null);
    .form-control {
        border-color: $__light-gray;
        color: $__gray;
        font-size: $__font__size__15;
        min-height: 46px;
        @include margin-padding(null , 6px 15px);
        font-weight: $__medium;
    }
}
.form-title {
    font-size: $__font__size__20;
    color: $__black;
    @include margin-padding(0 0 15px,null);
}
.list-inline {
    padding-left: 0;
    list-style: $__none;
    .updatebtn {
        float: $__left;
        background: $__primarycolor;
        color: $__white;
        font-size: $__font__size__15;
        border: 1px solid $__primarycolor;
        @include margin-padding(null,5px 15px);
        @include rounded(4px);
        @include transition(all 300ms ease-in 0s);
    }
}
.pricing-box{
    .pricing-header {
        margin-bottom: 2rem;
        h2 {
            margin-bottom: 0;
            font-size: $__font__size__20;
            font-weight: $__medium;
            line-height: 1.2;
        }
        p {
            color: $__ligt__carbon;
            font-size: $__font__size__20;
            line-height: 1.43;
        }
    }
    .pricing-card-price {
        margin-bottom: 1.563rem;
        .heading2 {
            margin-bottom: 5px;
        }

    }
    .pricing-options {
        list-style: $__none;
        @include margin-padding(0 0 1.875rem,0);
    }
    .pricing-selected {
        .card {
            background-color: $__primarycolor;
            border-color: $__primarycolor;
            color: $__white;
            h2 {
                color: $__white;
            }
            p {
                color: $__white;
            }
            .btn {
                background-color: $__white;
                color: $__primarycolor;
            }
        }
    }
}
.plan-det{
    p {
        margin-bottom: 20px !important;
    }
}
.review-card {
    .card-body {
        @include margin-padding(null,0 15px);
        .review-list {
            border-bottom: 1px solid $__gray__opaque;
            @extend %display-flex;
            @extend %flex-wrap;
            @include margin-padding(null,15px 0);
            @include respond-below(custom767) {
                flex-direction: column;
            }
            .review-img {
                flex: 0 0 70px;
                max-width: 70px;
                margin-right: 15px;
                img {
                    width: 70px;
                    height: 70px;
                    object-fit: $__cover;
                }
            }
            .review-info {
                a {
                    color: $__basecolor;
                }
                h5 {
                    font-size: $__font__size__17;
                    margin-bottom: 2px;
                }
                .review-date {
                    font-size: $__font__size__14;
                    color: $__basecolor;
                    margin-bottom: 5px;
                }
            }
            .review-count {
                flex: 0 0 auto;
                margin-left: $__auto;
                @extend %display-flex;
                @extend %flex-end;
                @extend %align-items-center;
                .rating {
                    list-style: $__none;
                    @include margin-padding(0 0 7px,0);
                    width: $__full__width;
                    .filled {
                        color: $__orangecolor;
                    }
                }
            }
        }
    }
}
