.hotel-details {
  &__price {
    font-size: 1.1rem;
    color: #132945;
    padding-bottom: 1rem;

    strong {
      font-size: 1.7rem;
      font-weight: 700;
      color: #f58220;
    }
  }

  &__address-details {

    &__city {
      display: $__inline__block;
      margin-bottom: 5px;
      color: $__dark__blue;
      font-size:$__font__size__16;
      font-weight: $__semibold;
    }

    &__street {
      margin-bottom: 5px;
      @extend %align-items-center;
      @extend %display-flex;
      .online {
        color: $__success__light;
        margin-right: 5px;
        font-size: $__font__size__12;
      }
    }
  }
}
