@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');

$__body__font__family:'Poppins', sans-serif;


$__bold:700;
$__semibold:600;
$__medium:500;
$__regular:400;
$__light:300;

$__body__font__size:15px;
$__menu__font__size:16px;
$__button__font__size:18px;
$__font__size__10:10px;
$__font__size__11:11px;
$__font__size__12:12px;
$__font__size__13:13px;
$__font__size__14:14px;
$__font__size__15:15px;
$__font__size__16:16px;
$__font__size__17:17px;
$__font__size__19:19px;
$__font__size__18:18px;
$__font__size__20:20px;
$__font__size__22:22px;
$__font__size__24:24px;
$__font__size__25:25px;
$__font__size__26:26px;
$__font__size__28:28px;
$__font__size__30:30px;
$__font__size__32:32px;
$__font__size__36:36px;
$__font__size__40:40px;
$__font__size__42:42px;
$__font__size__50:50px;
$__font__size__54:54px;
$__font__size__65:65px;

h1{
	font-weight:$__normal;
	font-size:$__font__size__40;
	@include respond-below(custom767){
		font-size:$__font__size__26 + 1;
	}
}
h2{
	font-weight:$__normal;
	font-size:$__font__size__30;
	@include respond-below(custom767){
		font-size:$__font__size__22 + 1;
	}
}
h3{
	font-weight:$__normal;
	font-size:$__font__size__24;
	@include respond-below(custom1023){
		font-size:$__font__size__20;
	}
	@include respond-below(custom767){
		font-size:$__font__size__16;
	}
}
h4{
	font-weight:$__normal;
	font-size:$__font__size__20 + 1;
	@include respond-below(custom1023){
		font-size:$__font__size__18;
	}
	@include respond-below(custom767){
		font-size:$__font__size__18;
	}
}
h5{
	font-weight:$__normal;
	font-size:$__font__size__18 + 1;
	@include respond-below(custom1023){
		font-size:$__font__size__18;
	}
}
h6{
	font-weight:$__normal;
	font-size:$__font__size__18;
	@include respond-below(custom1023){
		font-size:$__font__size__16;
	}
}


::-webkit-input-placeholder { /* WebKit, Blink, Edge */
	color:    $__metalic__gray;
	font-size: 13px;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	color:    $__metalic__gray;
	opacity:  1;
	font-size: 13px;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
	color:    $__metalic__gray;
	opacity:  1;
	font-size: 13px;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color:    $__metalic__gray;
	font-size: 13px;
}
::-ms-input-placeholder { /* Microsoft Edge */
	color:    $__metalic__gray;
	font-size: 13px;
}

::placeholder { /* Most modern browsers support this now. */
	color:    $__metalic__gray;
	font-size: 13px;
}


.fs-1 {
	font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
	font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
	font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
	font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
	font-size: 1.25rem !important;
}

.fs-6 {
	font-size: 1rem !important;
}

.fs-7 {
	font-size: 0.9rem !important;
}

.fs-8 {
	font-size: 0.8rem !important;
}

.fs-9 {
	font-size: 0.7rem !important;
}

.fst-italic {
	font-style: italic !important;
}

.fst-normal {
	font-style: normal !important;
}

.fw-light {
	font-weight: 300 !important;
}

.fw-lighter {
	font-weight: lighter !important;
}

.fw-normal {
	font-weight: 400 !important;
}

.fw-bold {
	font-weight: 700 !important;
}

.fw-bolder {
	font-weight: bolder !important;
}

.lh-1 {
	line-height: 1 !important;
}

.lh-sm {
	line-height: 1.25 !important;
}

.lh-base {
	line-height: 1.5 !important;
}

.lh-lg {
	line-height: 2 !important;
}

.text-start {
	text-align: left !important;
}

.text-end {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

.text-decoration-none {
	text-decoration: none !important;
}

.text-decoration-underline {
	text-decoration: underline !important;
}

.text-decoration-line-through {
	text-decoration: line-through !important;
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.text-wrap {
	white-space: normal !important;
}

.text-nowrap {
	white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
	word-wrap: break-word !important;
	word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
	--bs-text-opacity: 1;
	color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
	--bs-text-opacity: 1;
	color: #6c757d !important;
}

.text-black-50 {
	--bs-text-opacity: 1;
	color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
	--bs-text-opacity: 1;
	color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
	--bs-text-opacity: 1;
	color: inherit !important;
}

.text-opacity-25 {
	--bs-text-opacity: 0.25;
}

.text-opacity-50 {
	--bs-text-opacity: 0.5;
}

.text-opacity-75 {
	--bs-text-opacity: 0.75;
}

.text-opacity-100 {
	--bs-text-opacity: 1;
}
