.breadcrumb-bar {
    background-color: $__light__white;
	@include margin-padding(0,30px 0);
	.breadcrumb-title {
		color: $__grey__color;
		font-size: $__font__size__22;
		font-weight: $__bold;
		@include respond-below(custom575) {
			text-align: center;
		}
		h2 {
			font-weight: $__medium;
			font-size: $__font__size__25;
			@include margin-padding(0,0);
		}
	}
	@include respond-below(custom575) {
		.breadcrumb-menu{
			display: $__none;
		}
	}
}
.page-breadcrumb ol {
    background-color: transparent;
    font-size:$__font__size__14;
    @include margin-padding(0,0);
	a {
		color: $__grey__color;
	}
	.breadcrumb-item + .breadcrumb-item {
		&::before{
			color: $__grey__color;
		}
	}
}

