
.submit-section {
    .submit-btn {
        font-weight: 500;
        font-size: 15px;
        padding: 10px 20px;
        min-width: 150px;
    }
}
.btn{
    border: 0;
    cursor: pointer;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 300;
    margin: 0;
    padding: 12px 30px;

    &-primary {
        background: $__primarycolor;
        color: #fff;
    }

    &:hover{
        color:$__white;
    }
    &.btn-danger.trash {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        height: 46px;
        width: 46px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
    }
}

.check {
    display: block;
    margin: 0;
    padding: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute;
}
.checktoggle {
    background-color: $__basecolor;
    border-radius: 12px;
    cursor: pointer;
    display: block;
    font-size: 0;
    height: 24px;
    margin-bottom: 0;
    position: relative;
    width: 48px;
}
.checktoggle:after {
    content: ' ';
    display: block;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(5px, -50%);
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    transition: left 300ms ease, transform 300ms ease;
}
.check:checked + .checktoggle {
    background-color: $__primarycolor;
}
.check:checked + .checktoggle:after {
    left: 100%;
    transform: translate(calc(-100% - 5px), -50%);
}
.onoffswitch {
    margin-left: auto;
    position: relative;
    width: 73px;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select: none;
}
