body{
	font-family: $__body__font__family;
	letter-spacing: normal;
	font-size:$__body__font__size;
	color:$__default__color;
	font-weight: $__normal;
	overflow-x: hidden;
	line-height: normal;

}

#app {
	//margin-top: 120px;
}

.container{
	width:$__full__width;
	max-width:$__container__width;
	padding-left:$__grid__padding__left;
	padding-right:$__grid__padding__right;
	margin:$__margin__auto;
}
img {
	max-width:$__full__width;
	height: $__auto;
}
a{
	text-decoration: none;
	color:$__primarycolor;
	cursor: pointer;
	@include transition(all 0.2s ease);
	&:hover{
		@include transition(all 0.2s ease);
	}
	&:focus {
		outline: 0;
	}
}
input:focus,
select:focus,
.form-control:focus {
	box-shadow: none;
	border-color: #dcdcdc;
}
p{
	margin-bottom:20px;
	&:last-child{
		margin-bottom:0;
	}
}
strong{
	font-weight: $__bold;
}
