.contact-details {
    border: 1px solid rgba(0,0,0,.1);
    @include margin-padding(0,20px);
    @include rounded(5px);
    .contact-info {
        @extend %display-flex;
        i {
            font-size: $__font__size__18;
            @include margin-padding(0,3px 0 0);
            min-width: 18px;
        }
        .contact-data {
            @include margin-padding(0 0 0 15px,null);
        }
    }
}
.contact-map {
    border: 0;
    display: $__block;
    width: $__full__width;
    height: 450px;
    @include margin-padding(0,null);
}