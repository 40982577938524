.admin-panel {
    .loan-set {
        h3 {
            font-size: $__font__size__12;
            color: $__dark__ash;
            font-weight: $__regular;
        }
    }
    .slider {
        -webkit-appearance: $__none;
        width: $__full__width;
        height: 10px;
        background: linear-gradient(to right, $__primarycolor 0%, $__primarycolor 50%, #c4c4c4 50%, #c4c4c4 100%);
        outline: $__none;
        -webkit-transition: .2s;
        transition: opacity .2s;
        @include rounded(10px);
        &::-webkit-slider-thumb:hover {
            background: $__black;
        }
        &::-moz-range-thumb:hover {
            background: $__black;
        }
        &::-webkit-slider-thumb {
            -webkit-appearance: $__none;
            appearance: $__none;
            width: 25px;
            height: 25px;
            cursor: $__pointer;
            background: $__primarycolor;
            border:2px solid $__whitecolor;
            box-shadow: 0px 4px 14px rgba(154, 154, 154, 0.25);
            @include rounded(50%);
        }
        &::-moz-range-thumb {
            width: 38px;
            height: 38px;
            background: $__whitecolor;
            box-shadow: 0px 4px 14px rgba(154, 154, 154, 0.25);
            @include rounded(50%);
        }
    }
    .form-groupbtn {
        .btn {
            min-width: 104px;
            font-size: $__font__size__16;
            @include margin-padding(null ,7px 15px);
            @include respond-below(custom992) {
                min-width:$__auto;
            }
        }
        .btn-update {
            border: 1px solid $__primarycolor;
            color: $__whitecolor;
            background: $__primarycolor;
            &:hover {
                background: $__transparent;
                color: $__primarycolor;
            }
        }
        .btn-cancel {
            border: 1px solid $__plum__black;
            color: $__plum__black;
            &:hover {
                background: $__plum__black;
                color: $__whitecolor;
            }
        }
    }
    .settings-btn {
        border: 1px solid $__grey__color;
        color: $__black;
        cursor: $__pointer;
        font-weight: $__regular;
        position: $__relative;
        width: $__full__width;
        height: 50px;
        @include margin-padding(null ,15px);
        @include rounded(5px);
        .hide-input {
            position: $__relative;
            z-index: 1;
            cursor: $__pointer;
            min-height: $__auto;
            line-height: 10px;
            width:$__full__width;
            opacity: 0;
            @include margin-padding(null ,0px 0 0 4px);
        }
        .upload {
            z-index: 11;
            background: $__primarycolor;
            width: 44px;
            height: 45px;
            font-size: $__font__size__20;
            cursor: $__pointer;
            color: $__whitecolor;
            @include position($__absolute,1.5px,2px,null,null);
            @extend %justify-content-center;
            @extend %align-items-center;
            @extend %display-flex;
            @include rounded(5px);
        }
    }
    .settings-size {
        font-size: $__font__size__14;
        color: $__night;
        font-weight: $__regular;
        @include margin-padding(10px 0 0 ,null);
    }
    .upload-images {
        background: rgba(255, 255, 255, 0.8);
        border: 1px solid $__light__beige;
        margin-top: 20px;
        width: 196px;
        height: $__auto;
        justify-content: space-around;
        position: $__relative;
        @include margin-padding(null ,5px);
        @extend %align-items-center;
        @extend %display-flex;
        @include rounded(5px);
        h6 {
            font-size: $__font__size__12;
            font-weight: $__semibold;
            margin-top: 10px;
        }
        h5 {
            font-size: $__font__size__10;
            font-weight: $__regular;
        }
        &.upload-imagesprofile  {
            width: 80px;
            height: 80px;
            @include margin-padding(null ,5px);
            display: $__block;
            img {
                width: 68px;
                height: 68px;
                background: $__dark__beige;
                @include margin-padding(null ,10px);
                @include rounded(5px);
            }

        }
        img {
            max-height: 195px;
            width: $__full__width;
        }
        .btn-icon {
            color: $__primarycolor;
            font-size: $__font__size__16;
            text-align: $__center;
            width: 15px;
            height: 15px;
            background: $__whitecolor;
            font-size: $__font__size__9;
            border: 1px solid $__primarycolor;
            @include margin-padding(null ,0);
            @include position($__absolute,10px,10px,null,null);
            @extend %justify-content-center;
            @extend %align-items-center;
            @extend %inline-flex;
            @include rounded(50%);
        }
    }
    .check {
        display: $__block;
        width: 0;
        height: 0;
        visibility: $__hidden;
        opacity: 0;
        pointer-events: $__none;
        position: $__absolute;
        @include margin-padding(0 , 0);
    }
    .check:checked + .checktoggle {
        background-color: $__primarycolor;
        &:after {
            left: 100%;
            transform: translate(calc(-100% - 5px), -50%);
        }
    }
    .checktoggle {
        background-color: $__basecolor;
        cursor: $__pointer;
        display: $__block;
        font-size: 0;
        height: 24px;
        margin-bottom: 0;
        position: $__relative;
        width: 48px;
        @include rounded(12px);
        &:after {
            content: ' ';
            display: $__block;
            width: 16px;
            height: 16px;
            background-color: $__whitecolor;
            transition: left 300ms ease, transform 300ms ease;
            @include transform(translate(5px, -50%));
            @include position($__absolute,50%,null,null,0);
            @include rounded(50%);
        }
    }
    .card-heads {
        justify-content: space-between;
        @extend %display-flex;
    }
    .f-14 {
        font-size: $__font__size__14 !important;
    }
    .custom-control-inline {
        @extend %inline-flex;
        margin-right: 1rem;
        &.custom-radios {
            padding-left: 25px;
        }
        .custom-control-input {
            z-index: -1;
            width: 1rem;
            height: 1.25rem;
            opacity: 0;
            @include position($__absolute,null,null,null,0);
            &:checked ~.custom-control-label{
                &:after{
                    background: $__primarycolor;
                }
                &:before{
                    border: 1px solid $__primarycolor;
                }
            }
            & +label {
                position: $__relative;
                &::before {
                    content: "";
                    width: 18px;
                    height: 18px;
                    border: 1px solid $__sandstone;
                    background: $__whitecolor !important;
                    @include position($__absolute,2px,null,null,-22px);
                    @include rounded(50%);
                }
                &::after {
                    content: "";
                    background: $__sandstone;
                    width: 10px;
                    height: 10px;
                    @include position($__absolute,6px,null,null,-18px);
                    @include rounded(50%);
                }
            }
        }

    }
    .color-set {
        width: 25px;
        height: 25px;
        display: $__block;
        @include rounded(5px);
        @include margin-padding(0 15px 0 25px,null);
        &.clr-orange {
            background: #FC8543;
        }
        &.clr-blue {
            background: #0F6FDE;
        }
        &.clr-red {
            background: #F43F3F;
        }
        &.clr-green {
            background: #17D053;
        }
    }
    .clr-default {
        background: $__dark__candy;
    }
    .form-head {
        span {
            color: $__red;
            font-size: $__font__size__10;
            margin-left: 10px;
        }
    }
    .socail-links-set{
        ul {
            list-style: $__none;
            @include margin-padding(0 ,0);
            .dropdown{
                position: $__relative;
                .dropdown-toggle{
                    background: $__granite;
                    color: $__whitecolor;
                    @include rounded(5px);
                    &::after {
                        display: $__inline__block;
                        margin-left: 0.255em;
                        vertical-align: 0.255em;
                        content: "";
                        border-top: 0.3em solid;
                        border-right: 0.3em solid $__transparent;
                        border-bottom: 0;
                        border-left: 0.3em solid $__transparent;
                        @include position($__absolute,17px,10px,null,null);
                    }
                    .user-img {
                        display: $__inline__block;
                        margin-right: 3px;
                        position: $__relative;
                    }

                }
            }
        }
    }
}
