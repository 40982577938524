#carousel .carousel-item.boat {
  background-image: url("../../../assets/img/hero/hero-slide-1.jpg");
}

#carousel .carousel-item.sea {
  background-image: url("../../../assets/img/hero/hero-slide-2.jpg");
}

#carousel .carousel-item.river {
  background-image: url("../../../assets/img/hero/hero-slide-3.jpg");
}

#carousel .carousel-item {
  height: 700px;
  width: 100%;
  min-height: 350px;
  background: no-repeat center center scroll;
  background-size: cover;
  z-index: 1 !important;
}

#carousel .carousel-inner .carousel-item {
  transition: -webkit-transform 2s ease;
  transition: transform 2s ease;
  transition: transform 2s ease, -webkit-transform 2s ease;
}

#carousel .carousel-item .caption {
  //background-color: rgba(0, 0, 0, 0.5);
  padding: 40px;
  color: white;
  animation-duration: 1s;
  animation-delay: 2s;
}

#carousel .caption h2 {
  font-size: 40px;
  color: #fff;
  font-weight: 700;
  margin: 0 0 10px;

  animation-duration: 1s;
  animation-delay: 2s;
}

#carousel .caption h2 span {
  color: #ffc107;
}

#carousel .caption p {
  animation-duration: 1s;
  animation-delay: 2.2s;
}

#carousel .caption a {
  animation-duration: 1s;
  animation-delay: 2.4s;
}

/* Button */
.btn-carousel {
  border: 1px solid #f58220;
  cursor: pointer;
  background: #f58220;
  border-radius: 40px;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  margin: 0;
  padding: 15px 40px;
}

.btn-carousel.active, .btn-carousel:hover, .btn-carousel:focus {
  color: #ffffff;
  background-color: #ffc107;
  border-color: #ffc107;
}
