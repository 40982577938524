.footer {
    background-color: $__basecolor;
    border-top: 1px solid $__lights__blue;
    color: $__white;
    .footer-top {
        @include margin-padding(null, 80px 0);

        .footer-widget{
            @include respond-below(custom991) {
                margin-bottom: 30px;
            }
            .footer-title {
                color: $__primarycolor;
                font-size: $__font__size__22;
                font-weight: $__semibold;
                margin-bottom: 30px;
                text-transform: $__capitalize;

                .first-word {
                    color: $__white;
                }
            }
            ul {
                @include margin-padding(0,0);
                @extend %ul_reset;
                li {
                    margin-bottom: 7px;
                    position: $__relative;
                    display: $__block;
                    a {
                        color: $__white;
                        font-size: 0.8rem;
                        @include transition(all 0.5s ease);

                        &:hover {
                            text-decoration: none;
                            color: $__primarycolor;
                        }
                    }
                }
            }

            .footer-contact-info {
                color:  $__white;
                font-size: $__font__size__14;
                .footer-address {
                    @extend %display-flex;
                    span {
                        margin-right: 20px;
                        i {
                            color: $__primarycolor;
                            font-size: $__font__size__18;
                        }
                    }

                }
                p {
                    font-size: $__font__size__14;
                    color:  $__white;
                    i {
                        color: $__primarycolor;
                        font-size: $__font__size__18;
                        margin-right: 15px;
                    }
                }
            }
            .social-icon {
                ul{
                    @extend %inline-flex;
                    @extend %ul_reset;
                    li {
                        margin-right: 10px;
                        a {
                            color: $__white;
                            padding-right: 1rem;
                            font-size: 1.5rem;

                            @include rounded(14px);
                            @extend %align-items-center;
                            @extend %justify-content-center;
                            @extend %inline-flex;
                            @include transition(all 0.5s ease);

                        }
                    }
                }

            }
            .subscribe-form {
                position: $__relative;
                margin-top: 30px;
                .form-control {
                    height: 45px;
                    padding-left: 20px;
                    font-size: $__font__size__14;
                    @include rounded(50px);
                    @include box-shadow(null, 0, 0px, 20px, 0, rgba(0, 0, 0, 0.08));
                }
                .footer-btn {
                    width: 35px;
                    height: 35px;
                    font-size: $__font__size__16;
                    color: $__white;
                    background-color: $__primarycolor;
                    @include rounded(50px);
                    @include transition(all 0.5s ease);
                    @include margin-padding(0,0);
                    @include position($__absolute,5px,6px,null,null);
                }
            }
        }
    }
    .copyright {
        border-top: 1px solid $__lights__blue;
        @include margin-padding(null, 30px 0);
    }
    .copyright-text  {
        p{
            color: $__white;
            font-size: $__font__size__14;
            a {
                color: $__primarycolor;
                @include transition(all 0.2s ease);
            }
        }
    }
    .policy-menu {
        text-align: $__right;
        font-size: $__font__size__14;
        @include margin-padding(0,0);
        li {
            display: $__inline__block;
            margin-right: 15px;
            a {
                color: $__grey__color;
                &:hover {
                    color: $__primarycolor;
                }
            }
            &::after {
                color: $__grey__color;
                content: "|";
                font-weight: $__light;
                @include position($__relative,null,null,null,10px);
            }
            &:last-child::after {
                content: "";
            }
        }
    }
}
