.pagination {
    float: $__left;
    width: $__full__width;
    text-align: $__center;
    margin-top: 40px;
    padding-left: 0;
    list-style: $__none;
	@extend %display-flex;
	ul {
		float: $__left;
		width:  $__full__width;
		text-align:  $__center;
		@include margin-padding(0,0);
		li {
			float: $__none;
			display: $__inline__block;
			margin: 0 1px;
			&.active{
				a {
					background: $__primarycolor;
					border-color: $__primarycolor;
					color: $__white;
				}
			}
			a {
				float: $__left;
				width: 35px;
				height: 35px;
				background: $__white;
				border: 1px solid $__metallic-silver;
				line-height: 34px;
				font-size: $__font__size__13;
				color: $__grey__color;
			}
			&.arrow a {
				background: $__light__vampire;
				font-size: $__font__size__17;
				margin: 0 9px;
			}
		}
	}
	
}